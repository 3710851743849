/*----------Botones----------*/
/* .boton_4{
    margin: 50px 25px;
    font-size: 14px;
    font-weight: bold;
    height: 50px;
    width: 200px;
    background-color: #970D19;
    border: solid 1px #970D19;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    font-family: 'Montserrat';
}
.boton_4 p{
    margin: 12px;
    text-align: center;
    color: white;
    transition: all 0.5s ease-in-out;
}
.boton_4:hover{
    box-shadow: 0px 0px 15px #970D19;
    transition: all 0.5s ease-in-out;
}
.boton_4:focus{
    background-color: #970D19;
} */
/*----------Fin Botones----------*/



/*----------Box----------*/
.caja_8{
    margin: 0px;
    width: 436px;
}

.caja_16{
    width: 340px;
}

.caja_17{
    width: 436px;
    margin: 0px;
}
/*----------Fin Box----------*/



/*----------Contenedores----------*/
.contenedor{
    padding: 0px 15%;
}
/*----------Fin Contenedores----------*/



/*----------Formularios----------*/
.password{
    width: 300px !important;
    border: solid 1px #707070 !important;
    margin-top: 10px !important;
    text-align: center;
}
.password::placeholder{
    font-size: 14px;
    font-style: italic;
    color: #5F5F5F;
    text-align: center;
}
/*----------Fin Formularios----------*/



/*----------Imagenes----------*/
.imagen_aspirantes_2{
    width: 100%;
}

.icon_login{
    width: 70px;
    height: 70px;
}
/*----------Fin Imagenes----------*/



/*----------Titulos y textos----------*/

/* .texto_3{
    font-family: "Montserrat";
    font-size: 14px;
} */
/*----------Fin Titulos y textos----------*/


.boton_pdf{
    width: 200px;
    height: 120px;
    margin: 10px;
    background: #e4e4e4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #000000;
    transition: all 0.5s ease-in-out;
}
.boton_pdf:hover{
    box-shadow: 0px 0px 15px #bbbbbb;
    transition: all 0.5s ease-in-out;
}

.boton_pdf_2{
    width: 200px;
    height: 120px;
    margin: 10px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 0px 5px #bbbbbb;
    color: #000000;
    transition: all 0.5s ease-in-out;
}
.boton_pdf_2:hover{
    box-shadow: 0px 0px 15px #bbbbbb;
    transition: all 0.5s ease-in-out;
}

@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/
    .boton_pdf{
        height: 80px;
    }
    
    .boton_pdf_2{
        height: 80px;
    }

    .caja_8{
        width: 190px;
    }

    .caja_16{
        width: 260px;
    }

    .caja_17{
        width: 203px;
    }

    .contenedor{
        padding: 0px 8%;
    }

    .contenedor_pdf{
        padding: 30px 5%;
    }

    .password{
        width: 260px !important;
    }

    .icon_login{
        margin-top: 20px;
        width: 50px;
        height: 50px;
    }

    .texto_3{
        font-size: 10px;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .contenedor{
        padding: 0px 10%;
    }

    .contenedor_pdf{
        padding: 0px 5%;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){
    .contenedor_pdf{
        padding: 50px;
    }
}
  
@media only screen and (min-width:1665px) {
    .contenedor_pdf{
        padding: 10% 100px;
    }
}