.preloader-caav {
    height: 100%;
    background-color: #ffffff00;
}
  
.loader-caav {
    border: 16px solid #ffffff31;
    border-top: 16px solid #1C496A;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
  