#banco, #formulario, #precios {
    scroll-margin-top: 200px;
}

.rein-fondo-1{
    background: #DE2657;
    height: 117px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
}
.rein-fondo-1 .font-medium{
    margin-top: 10px !important;
}

.rein-fondo-2{
    font-weight: 500;
    background: #02CAD7;
    height: 117px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 80px !important;
    border-radius: 18px;
    margin-top: 15px;
}
.rein-fondo-2 .font-medium{
    margin-top: 10px !important;
}

.rein-fondo-3 {
    width: 48.5% !important;
    border-radius: 18px;
    background: #FFF;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    padding: 5px !important;
}
.rein-fondo-3 .titulo_4_nv{
    font-size: 17px;
}
.rein-fondo-3 > div{
    width: 100%;
    font-weight: 500;
    background: #F5F5F7;
    height: 117px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 18px;
}

.rein-fondo-4{
    background-color: #F0F2F6;
    padding: 20px 0 20px 0;
}
.rein-fondo-5{
    background-color: #ffffff;
    padding: 20px 0 20px 0;
}

.rein-fondo-7 {
    width: 48.5% !important;
    border-radius: 18px;
    background: #FFF;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    padding: 5px !important;
}


.rein-fondo-7 > div{
    width: 100%;
    font-weight: 500;
    background: #F5F5F7;
    height: 197px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 18px;
}
.rein-fondo-7 > div p{
    margin: 5px 0 0 0;
}
.rein-fondo-7 > div .titulo_4_nv{
    margin: 0;
}


.rein-fondo-8{
    background: #DE2657;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    padding: 30px 0 !important;
}
.rein-fondo-8 p{
    margin: 5px 0 0 0;
}
.rein-fondo-8 .titulo_4_nv{
    margin: 0;
}

.rein-fondo-9{
    font-weight: 500;
    background: #02B5C1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 80px !important;
    border-radius: 18px;
    margin-top: 15px;
    padding: 30px 0 !important;
}
.rein-fondo-9 p{
    margin: 5px 0 0 0;
}
.rein-fondo-9 .titulo_4_nv{
    margin: 0;
}

.rein-fondo-10 {
    width: 48.5% !important;
    border-radius: 18px;
    background: #F5F5F7;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px !important;
    margin: 0 !important;
}
.rein-fondo-10 > div{
    width: 100%;
    font-weight: 500;
    background: #ffffff;
    height: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 18px;
}
.rein-fondo-10-1 {
    width: 48.5% !important;
    border-radius: 18px;
    background: #F5F5F7;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px !important;
    margin: 0 !important;
}
.rein-fondo-10-1 > div{
    width: 100%;
    font-weight: 500;
    background: #ffffff;
    height: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 18px;
}

.rein-fondo-11 {
    width: 100% !important;
    border-radius: 18px;
    background: #F5F5F7;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    padding: 5px;
}
.rein-fondo-11 > div{
    width: 100%;
    font-weight: 500;
    background: #ffffff;
    height: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 0;
    border-radius: 18px;
}

.card-reinscripcion{
    padding: 22px 30px;
    border-radius: 18px;
    background: #FFF;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    height: 300px;
    color: #6E6E73;
    cursor: default;
    text-align: center;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: column;
    width: 313px !important;
    margin: 5px;
}
.card-reinscripcion p {
    user-select: none;
}
.card-reinscripcion .titulo_4_nv {
    margin-top: 0px;
}
.color-card-1{
    background-color: #BA2049 !important;
}

.card-pago > div{
    background-color: #F5F5F7;
    height: 400px;
    width: 400px;
    padding: 25px 30px;
    border-radius: 18px;
}
.card-pago .titulo_4_nv{
    margin: 0;
}
.card-pago p {
    user-select: none;
    color: #6E6E73;
}

/* carrusel de informacion */
.styles-module_item-provider__YgMwz{
    width: 100% !important;
}

.rein-fondo-4 .styles-module_carousel-base__3keqD{
    justify-content: end;
    height: 320px;
}
.rein-fondo-5 .styles-module_carousel-base__3keqD{
    justify-content: end;
    height: 400px;
}
.rein-fondo-6 .styles-module_carousel-base__3keqD{
    justify-content: center;
    height: 405px;
}

/* inicio - botones */
.boton-rein-nv{
    color: #FFF;
    display: flex;
    width: 243px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    border: none;
}
/* fin - botones */

/* inicio - textos */
.titulo-rein-1{
    color: #B52C93;
    text-align: center;
    font-family: "Montserrat-Bold";
    letter-spacing: -0.096px;
}

/* fin - textos */

.margen-rein-1{
    margin-top: 25px;
}


@media only screen and (min-width: 200px) and (max-width: 550px){
    .margin-bottom{
        margin-bottom: 0;
    }

    .space-card{
        justify-content: space-around !important;
    }

    .margen-rein-1{
        margin-top: 15px;
    }

    .card-reinscripcion{
        padding: 12px 10px;
        width: 250px !important;
        height: 350px !important;
    }

    .card-pago > div{
        margin: 10px;
        width: 300px;
    }

    /* inicio - botones */
    .boton-rein-nv{
        color: #FFF;
        display: flex;
        width: 200px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 80px;
        border: none;
    }
    .boton-rein-nv-h{
        height: 50px;
        line-height: 17px;
    }
    /* fin - botones */

    .rein-fondo-1 .font-medium{
        margin-top: 0px !important;
    }
    
    .rein-fondo-2 .font-medium{
        margin-top: 0px !important;
    }
    .rein-fondo-2{
        padding: 0 30px !important;
    }

    .rein-fondo-7 > div{
        padding: 0 5px;
    }
    .rein-fondo-7 .titulo_4_nv{
        font-size: 17px;
    }

    .rein-fondo-8{
        padding: 20px !important;
    }
    .rein-fondo-8 .titulo_4_nv{
        font-size: 17px;
    }

    .rein-fondo-9{
        padding: 20px !important;
    }
    .rein-fondo-9 .titulo_4_nv{
        font-size: 16px;
    }

    .rein-fondo-10 > div{
        height: 225px;
    }
    .rein-fondo-10 .titulo_4_nv{
        font-size: 16px;
    }

    .rein-fondo-10-1 {
        width: 100% !important;
    }
    .rein-fondo-10-1 > div{
        height: auto;
        padding: 15px 0;
    }
    .rein-fondo-10-1 .titulo_4_nv{
        font-size: 16px;
    }

    .rein-fondo-11 .titulo_4_nv{
        font-size: 16px;
    }

    .margin-top-0{
        margin-top: 0px;
    }

    .margtin-top-1{
        margin-top: 15px !important;
    }

    .titulo_4_rein{
        font-size: 14px;
        text-align: left !important;
    }
}
@media only screen and (min-width: 551px) and (max-width: 980px){}
@media only screen and (min-width: 981px) and (max-width: 1664px){}
@media only screen and (min-width: 1665px){}