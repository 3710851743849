body {
  margin: 0;
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-wrap: pretty;
  color: #6E6E73;
}

/* seccion de videos carrusel marcketing */
.swiper {
  width: 100% !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.swiper-slide {
  position: relative;
  aspect-ratio: 16/9;
  width: 500px !important;
  height: auto !important;
}
.swiper-slide container-video {
  display: block !important;
  width: 100% !important;
  object-fit: cover;
  user-select: none;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 ratio (1080/1920 * 100) */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.container-video {
  position: relative;
  z-index: 0;
  width: 100%;
  background-color: grey;
}
.container-video div{
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9;
}
.container-video img{
  width: 100%;
}
.play-icon {
  color: white;
  font-size: 50px; /* Ajusta el tamaño del icono según sea necesario */
}
/* seccion de videos carrusel marcketing */


/* pkcres date */
ul.dropdown-content{
  overflow-y: scroll;
  height: 200px !important;
}
.modal.datepicker-modal.open{
  top: 30% !important;
}

#dropdown1.dropdown-content{
  overflow-y: auto;
  height: auto !important;
}
#dropdown2.dropdown-content{
  overflow-y: auto;
  height: auto !important;
}
#dropdown3.dropdown-content{
  overflow-y: auto;
  height: auto !important;
}

b{
  text-wrap: pretty;
  font-family: 'Montserrat-Medium';
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: 'Montserrat' !important;
}

/* pop pop */
div:where(.swal2-container).swal2-backdrop-show, div:where(.swal2-container).swal2-noanimation{
  background: rgba(33, 33, 33, 0.71) !important;
}

div:where(.swal2-container) div:where(.swal2-popup){
  border-radius: 30px !important;
}
div:where(.swal2-container) button:where(.swal2-close){
  width: 47px !important;
  height: 47px !important;
  color: rgb(0, 0, 0, 0.8) !important;
  border-radius: 50px !important;
  box-shadow: 0 -2px 7px 0 #16161603,0 2px 10px 0 #5050501a !important;
  position: absolute;
  top: 10px;
  right: 10px;
}
div:where(.swal2-container) button:where(.swal2-styled){
  border-radius: 980px !important;
}
/* pop pop */

.visto {
  width: 25px;
  height: 25px;
  background-color: green;
}

.sin_ver {
  width: 25px;
  height: 25px;
  background-color: red;
}

/* Montserrat-ExtraBold.otf */
@font-face {
  font-family: 'Montserrat';
  src: url('./Font/Montserrat.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./Font/Montserrat-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./Font/Montserrat-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'noodle';
  src: url('./Font/big_noodle_titling.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Jost';
  src: url('./Font/Jost-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

.font-medium{
  font-family: "Montserrat-Medium";
}

/* textos - inicios */
.texto_pop{
  font-size: 14px !important;
}
.titulo_bold{ 
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
}

.d3_titulo_1_monserrat {
  font-size: 20px;
  font-family: 'Montserrat';
  line-height: 33px;
}

.d2_titulo_1 {
  font-size: 55px;
  font-family: 'Montserrat-Bold';
  margin: 10px 0px;
}

.collapsible-header {
  padding: 0px;
}

.titulo_9 {
  font-size: 55px;
  color: black;
  font-family: 'Montserrat-Bold';
}

.titulo_10 {
  font-size: 20px;
}
.d3_licenciatura {
  font-size: 60px;
  font-family: 'Montserrat-Bold';
  margin: 25px 0px;
}
.d3_licenciatura_2 {
  font-size: 30px;
  font-family: 'Montserrat-Bold';
  margin-top: 20px;
  margin-bottom: 0px;
}

.d3_licenciatura_sub {
  font-size: 32px;
  color: #707070;
  width: 820px;
  line-height: 40px;
}

.d3_licenciatura_sub_black {
  font-size: 15px;
  font-weight: bold;
}

.texto_licenciatura_campo_trabajo_1 {
  width: 752px;
  font-size: 14px;
}

.texto_licenciatura_campo_trabajo_2 {
  width: 435px;
  font-size: 14px;
}
.texto_licenciatura_campo_trabajo_3 {
  width: 370px;
  font-size: 14px;
}

.texto_lic_1 {
  font-size: 15px;
  font-weight: bold;
}

.texto_lic_3 {
  font-size: 15px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 0px;
}

.texto_lic_4 {
  font-size: 17px;
  font-weight: bold;
}

.texto_lic_6 {
  font-size: 30px;
  font-family: 'Montserrat-Bold';
}

.texto_lic_7 {
  font-weight: bold;
  padding-bottom: 0px;
  margin: 0px;
  padding-top: 0px;
  font-size: 15px;
}

.texto_lic_9 > p {
  margin: 0px;
}

.margin_licenciatura > p {
  margin: 0px;
}
/* textos - fin */

/* flexbox - inicio */

/* flexbox - fin */

@media only screen and (min-width: 200px) and (max-width: 550px) {
  .margin_licenciatura {
    margin-bottom: 20px;
  }

  .centrar_movil {
    text-align: center;
  }

  /* textos - inicios */
  .d3_titulo_1_monserrat {
    font-size: 13px;
    font-family: 'Montserrat';
    line-height: 33px;
  }

  .d2_titulo_1 {
    font-size: 30px;
  }

  .titulo_9 {
    font-size: 30px !important;
  }

  .titulo_10 {
    font-size: 13px;
  }
  .d3_licenciatura {
    font-size: 30px !important;
    margin-bottom: 0;
  }
  .d3_licenciatura_2 {
    font-size: 30px;
    font-family: 'Montserrat-Bold';
    margin: 0px;
  }

  .d3_licenciatura_sub {
    font-size: 13px;
    line-height: 15px;
  }

  .d3_licenciatura_sub_black {
    font-size: 13px;
  }

  .texto_lic_9 {
    font-size: 13px;
    text-align: left;
  }

  .texto_licenciatura_campo_trabajo_1 {
    width: 315px;
    font-size: 13px;
  }
  .texto_licenciatura_campo_trabajo_2 {
    width: 290px;
    font-size: 13px;
    margin-bottom: -22px;
  }
  .texto_licenciatura_campo_trabajo_3 {
    width: 290px;
    font-size: 13px;
    margin-top: 0;
  }

  .texto_lic_1 {
    font-size: 13px;
  }

  .texto_lic_3 {
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .texto_lic_4 {
    font-size: 13px;
    font-weight: normal;
  }
  .texto_lic_5 {
    font-size: 13px;
    font-weight: normal;
    margin-top: 0;
  }
  .texto_lic_7 {
    font-size: 13px;
    text-align: center;
  }
  .texto_lic_8 {
    font-size: 13px;
  }
  /* textos - fin */

  /* flexbox - inicio */

  /* flexbox - fin */

  .invisible_movil_redes {
    display: none !important;
  }

  .invisible_escritorio_redes {
    display: flex !important;
  }

  .separar_imagen_footer {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 850px) {
  .margin_licenciatura {
    margin-bottom: 20px;
  }

  .centrar_movil {
    text-align: center;
  }

  /* textos - inicios */
  .titulo_9 {
    font-size: 30px !important;
  }

  .d3_titulo_1_monserrat {
    font-size: 20px;
    font-family: 'Montserrat';
    line-height: 33px;
  }

  .d3_licenciatura_sub {
    font-size: 13px;
    line-height: 20px;
  }

  .d3_licenciatura_sub_black {
    font-size: 13px;
  }

  .texto_lic_9 {
    font-size: 13px;
    text-align: left;
  }

  .texto_licenciatura_campo_trabajo_1 {
    width: 100%;
    padding: 0px 70px;
    font-size: 13px;
  }
  .texto_licenciatura_campo_trabajo_2 {
    width: 100%;
    padding: 0px 80px;
    font-size: 13px;
    margin-bottom: -22px;
  }
  .texto_licenciatura_campo_trabajo_3 {
    width: 100%;
    padding: 0px 80px;
    font-size: 13px;
    margin-top: 0;
  }

  .texto_lic_1 {
    font-size: 13px;
  }

  .texto_lic_3 {
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .texto_lic_4 {
    font-size: 13px;
    font-weight: normal;
  }
  .texto_lic_5 {
    font-size: 13px;
    font-weight: normal;
    margin-top: 0;
  }
  .texto_lic_7 {
    font-size: 13px;
    text-align: center;
  }
  .texto_lic_8 {
    font-size: 13px;
  }

  .d3_licenciatura_2 {
    font-size: 30px;
    font-family: 'Montserrat-Bold';
    text-align: center;
    margin: 0px;
  }
  /* textos - fin */

  /* flexbox - inicio */

  /* flexbox - fin */

  .invisible_movil_redes {
    display: none !important;
  }

  .invisible_escritorio_redes {
    display: flex !important;
  }

  .separar_imagen_footer {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 851px) and (max-width: 1664px) {
  .invisible_movil_redes {
    display: flex !important;
  }

  .invisible_escritorio_redes {
    display: none !important;
  }
}

@media only screen and (min-width: 1665px) {
  .invisible_movil_redes {
    display: flex !important;
  }

  .invisible_escritorio_redes {
    display: none !important;
  }
}

/* nuevo css */

.video-principal > div {
  width: 100% !important;
  height: auto !important;
}

.s_margen > p {
  margin: 0px;
}

.puntos > li::before {
  content: url('./Images/Icon/56.png');
  background-position: center;
  background-repeat: no-repeat;
  margin-left: -10px;
  margin-top: -3px;
  padding-bottom: 5px;
  position: absolute;
}

/* titulos */
.texto_botones_index {
  font-family: 'Montserrat';
  font-weight: bold;
}

.encabezadosCaav {
  font-family: 'Montserrat-Bold', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 30px;
  line-height: 30px;
}

.titulo_1_nv {
  font-family: 'Montserrat-Bold', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 30px;
}

.titulo_4_nv {
  font-size: 20px;
  line-height: 25px;
}

.titulo_5_nv {
  font-size: 20px;
}

.titulo_fecha_nv {
  font-size: 42px;
  font-weight: bold;
  color: #fff;
}

.texto_s_margen > p {
  margin: 0px;
}
/* fin - titulos */

/* flexbox */
.flex_padre_1_nv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_padre_licenciatura {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.flex_hijo_1_nv {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex_1_licenciatura {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
/* fin - flexbox */

/* contenedores */
.licenciaturas_index_contenedor a > div {
  padding-top: 15px !important;
}

.contenedor_effecto_1 .efecto_licenciatura {
  width: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.texto_licenciatura_carrera {
  width: 553px;
  padding: 10px 20px;
}
/* fin - contenedores */

/* efectos */
.imagen_efecto_1 {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  width: 100%;
  overflow: hidden;
}
.imagen_efecto_1:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.imagen_efecto_2 {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  width: 100%;
  overflow: hidden;
}
.imagen_efecto_2:hover img {
  transform: scale(1.2) rotate(5deg);
  transition: all 0.3s ease-in-out;
}
/* fin - efectos */

/* botones */
.boton_0_nv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  width: 170px;
  height: 35px;
  padding: 6px 27px;
  background-color: #00496c;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}
.boton_0_nv p {
  font-weight: bold;
  font-size: 13px;
  color: white;
  margin: 0px;
}
.boton_0_nv:hover {
  box-shadow: 0px 0px 15px #00496c;
  transition: all 0.5s ease-in-out;
}

.boton_1_nv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  width: 190px;
  height: 35px;
  padding: 6px 27px;
  background-color: #a80938;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}
.boton_1_nv p {
  font-weight: bold;
  font-size: 13px;
  color: white;
  margin: 0px;
}
.boton_1_nv:hover {
  box-shadow: 0px 0px 15px #a80938;
  transition: all 0.5s ease-in-out;
}

.boton_2_nv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  width: 170px;
  height: 35px;
  padding: 6px 27px;
  background-color: #a80938;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  border: none;
}
.boton_2_nv button {
  background: transparent;
  border: none;
}
.boton_2_nv p {
  font-weight: bold;
  font-size: 13px;
  color: white;
  margin: 0px;
}
.boton_2_nv:hover {
  box-shadow: 0px 0px 15px #a80938;
  transition: all 0.5s ease-in-out;
}

.boton_3_nv {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  width: 190px;
  height: 35px;
  border: solid 1px #707070;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}
.boton_3_nv:hover {
  box-shadow: 0px 0px 15px #707070;
  transition: all 0.5s ease-in-out;
}
.boton_3_nv p {
  text-align: center;
  color: black;
  transition: all 0.5s ease-in-out;
}

.boton_5_nv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  height: 35px;
}
.boton_5_nv button {
  padding: 6px 27px;
  background-color: #00496c;
  border: #00496c;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  width: fit-content;
  font-weight: bold;
  font-size: 13px;
  color: white;
  margin: 0px;
  cursor: pointer;
}
.boton_5_nv:hover button {
  box-shadow: 0px 0px 15px #00496c;
  transition: all 0.5s ease-in-out;
}

.boton_6_nv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  height: 35px;
}
.boton_6_nv p {
  padding: 6px 27px;
  background-color: #a80938;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  width: fit-content;
  font-weight: bold;
  font-size: 13px;
  color: white;
  margin: 0px;
}
.boton_6_nv:hover p {
  box-shadow: 0px 0px 15px #a80938;
  transition: all 0.5s ease-in-out;
}

.boton_7_nv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  width: 170px;
  height: 35px;
  padding: 6px 27px;
  background-color: #00b5c1;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  border: none;
  cursor: pointer;
}
.boton_7_nv button {
  transition: all 0.5s ease-in-out;
  background: none;
  cursor: pointer;
  border: none;
}
.boton_7_nv p {
  font-weight: bold;
  font-size: 13px;
  color: white;
  margin: 0px;
}
.boton_7_nv:hover {
  box-shadow: 0px 0px 15px #00b5c1;
  transition: all 0.5s ease-in-out;
}

.boton_8_nv {
  max-height: 30px;
  font-weight: bolder;
  position: relative;
  top: -3px;
  padding: 2px 6px !important;
  background-color: transparent;
  color: #ffffff;
  border: solid 2px #fff;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  margin-left: 30px;
}
.boton_8_nv:hover {
  background-color: #ffffff;
  color: #ff007e;
  transition: all 0.5s ease-in-out;
}
/* fin - botones */

.imagen_licenciatura {
  position: relative;
  z-index: -1;
  overflow: hidden;
}

.fondo_nuevo {
  background-image: url(./Images/escritorio/fondo.png);
  width: 100%;
  /* height: 100vh; */
  background-position: center;
  background-repeat: no-repeat;
}

.fondo_mensaje {
  background-image: url(./Images/escritorio/Mensaje/2.png);
  width: 100%;
  /* height: 100vh; */
  background-position: center;
  background-repeat: no-repeat;
}

.contenedor_media {
  height: 0;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}
.media_contenido {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

/* swal */
.swal2-close:focus {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.swal2-close:focus-visible {
  background-color: transparent;
  border: none;
}

.swal2-actions {
  margin-top: 0 !important;
}

.line-1 {
  line-height: 25px;
}
/* swal */

@media only screen and (min-width: 200px) and (max-width: 550px) {
  /* seccion de videos carrusel marcketing */
  .swiper-slide {
    width: 200px !important;
  }
  /* seccion de videos carrusel marcketing */

  /* swal */
  .swal2-container.swal2-center > .swal2-popup {
    margin-bottom: -225px !important;
  }
  /* swal */
  .titulo_4_nv {
    font-size: 22px;
    line-height: 25px;
  }

  .texto_movil {
    font-size: 12px;
  }

  .container_chico_movil {
    padding: 0px 10%;
  }

  .encabezadosCaav {
    line-height: 31px;
  }

  .boton_4_nv {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    width: 150px;
    height: 35px;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
  }
  .boton_4_nv:hover {
    box-shadow: 0px 0px 15px #707070;
    transition: all 0.5s ease-in-out;
  }
  .boton_4_nv p {
    text-align: center;
    color: black;
    transition: all 0.5s ease-in-out;
  }

  .invisible_nuevo {
    display: none;
  }

  .imagen_licenciatura {
    width: 100%;
  }

  .center_movil {
    text-align: center !important;
    width: 100%;
  }

  .texto_licenciatura_carrera {
    width: 355px;
  }

  /* flexbox */
  .flex_padre_1_nv {
    flex-wrap: wrap;
  }

  .flex_padre_licenciatura {
    width: 315px;
  }

  .flex_hijo_1_nv {
    width: 100%;
    align-items: center;
  }
  /* fin - flexbox */

  /* titulos */
  .encabezadosMonserrat-1,
  .encabezadosMonserrat {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 13px;
  }

  .titulo_2_nv {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: black;
  }

  .titulo_3_nv {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 13px;
    color: black;
    font-weight: bold;
  }

  .titulo_frase {
    font-size: 13px;
    color: #707070;
  }

  .titulo_fecha_nv {
    font-size: 20px;
    font-weight: bold;
  }

  .titulo_5_nv {
    font-size: 13px;
  }
  /* fin - titulos */
}
@media only screen and (min-width: 551px) and (max-width: 980px) {
  .boton_4_nv {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    width: 190px;
    height: 35px;
    border: solid 1px #707070;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
  }
  .boton_4_nv:hover {
    box-shadow: 0px 0px 15px #707070;
    transition: all 0.5s ease-in-out;
  }
  .boton_4_nv p {
    text-align: center;
    color: black;
    transition: all 0.5s ease-in-out;
  }

  .invisible_nuevo {
    display: none;
  }

  .imagen_licenciatura {
    width: 100%;
  }

  /* flexbox */
  .flex_padre_licenciatura {
    width: 100%;
    justify-content: center;
  }

  .flex_hijo_1_nv {
    width: 50%;
  }
  /* fin - flexbox */

  .texto_licenciatura_carrera {
    width: 100%;
    padding: 10px 70px;
  }

  /* titulos */
  .encabezadosMonserrat-1,
  .encabezadosMonserrat {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
  }

  .titulo_2_nv {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .titulo_3_nv {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 13px;
    color: black;
    font-weight: bold;
  }

  .titulo_frase {
    font-size: 15px;
    color: #707070;
  }

  .titulo_5_nv {
    font-size: 13px;
  }
  /* fin - titulos */
}
@media only screen and (min-width: 981px) and (max-width: 1664px) {
  .boton_4_nv {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    width: 190px;
    height: 35px;
    border: solid 1px #707070;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
  }
  .boton_4_nv:hover {
    box-shadow: 0px 0px 15px #707070;
    transition: all 0.5s ease-in-out;
  }
  .boton_4_nv p {
    text-align: center;
    color: black;
    transition: all 0.5s ease-in-out;
  }

  .imagen_licenciatura {
    height: 100%;
    right: 25%;
  }

  /* contenedores */
  .contenedor_xch {
    width: 50%;
  }
  .contenedor_chico {
    width: 60%;
  }
  .contenedor_mediano {
    width: 80%;
  }
  .contenedor_grande {
    width: 90%;
  }
  /* fin - contenedores */

  /* flexbox */
  .flex_hijo_1_nv {
    width: 50%;
  }
  /* fin - flexbox */

  /* titulos */
  .encabezadosMonserrat {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
  }
  .encabezadosMonserrat-1 {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
  }

  .titulo_2_nv {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .titulo_3_nv {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    color: black;
  }

  .titulo_frase {
    font-size: 20px;
    color: #707070;
  }
  /* fin - titulos */
}
@media only screen and (min-width: 1665px) {
  body {
    font-size: 15px;
  }
  .texto_pop{
    font-size: 15px !important;
  }

  .imagen_licenciatura {
    height: 100%;
    right: 5%;
  }

  .boton_4_nv {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    width: 190px;
    height: 35px;
    border: solid 1px #707070;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
  }
  .boton_4_nv:hover {
    box-shadow: 0px 0px 15px #707070;
    transition: all 0.5s ease-in-out;
  }
  .boton_4_nv p {
    text-align: center;
    color: black;
    transition: all 0.5s ease-in-out;
  }

  /* contenedores */
  .contenedor_xch {
    width: 50%;
  }
  .contenedor_chico {
    width: 60%;
  }
  .contenedor_mediano {
    width: 80%;
  }
  .contenedor_grande {
    width: 90%;
  }
  /* fin - contenedores */

  /* flexbox */
  .flex_hijo_1_nv {
    width: 50%;
  }
  /* fin - flexbox */

  /* titulos */
  .encabezadosMonserrat {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
  }
  .encabezadosMonserrat-1 {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
  }

  .titulo_2_nv {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .titulo_3_nv {
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    color: black;
  }

  .titulo_frase {
    font-size: 20px;
    color: #707070;
  }
  /* fin - titulos */
}

/* fin - nuevo css */

/* 
@media only screen and (min-width:200px) and (max-width:550px){}
@media only screen and (min-width:551px) and (max-width:980px){}
@media only screen and (min-width:981px) and (max-width:1664px){}
@media only screen and (min-width:1665px){}
*/
