.contenedor_directorio_general{
    padding: 0 15%;
}

.contenedor_directorio{
    margin-top: 25px;
    margin-bottom: 10px;
    height: 80px;
}

.contenedor_directorio_1{
    width: 33.333333%;
}

.cuadrado {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; 
}

.imagen_contactos{
    width: 100%;
}

.hover_directorio_d3 > a > img{
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.hover_directorio_d3:hover > a > img{
    width: 32px;
    transition: all 0.3s ease-in-out;
}

.texto_directorio{
    font-weight: bold;
    text-align: center;
}

.texto_directorio_desc{
    text-align: center;
    margin-top: -15px;
}



@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/
    .contenedor_directorio_general{
        padding: 0px;
    }

    .contenedor_directorio{
        height: 100%; 
    }

    .contenedor_directorio_1{
        width: 50%;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .contenedor_directorio_general{
        padding: 0% 5%;
    }

    .contenedor_directorio_1{
        width: 50%;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .contenedor_directorio_general{
        padding: 0% 10%;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/
    .contenedor_directorio_general{
        padding: 0% 10%;
    }
}