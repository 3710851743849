/* textos */
.curso_d3{
    color: #843DC7;
    font-weight: bold;
    margin: 0px;
}

.curso_titulo_d3{
    font-weight: bold;
    margin: 0px;
}

.curso_impartido_d3{
    color: #707070;
    margin-top: 0px;
}

.curso_pagos_d3{
    margin: 0px;
}
/* fin - textos */

/* etiqueta curso */
.tipo_curso{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 0px;
    top: 10px;
    background: #ffffff;
}
.tipo_curso > p{
    margin: 0px;
}

.tipo_curso_2{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 0px;
    top: 10px;
    background: #ffffff;
}
.tipo_curso_2 > p{
    margin: 0px;
}

.tipo_precencial > p{
    text-align: center;
    width: 100px;
    font-size: 12px;
    padding: 2px 0px;
    background: #64C5DD;
    color: #ffffff;
}
.tipo_hibrido > p{
    width: 100px;
    font-size: 12px;
    padding: 2px 0px;
    text-align: center;
    background: #64C5DD;
    color: #ffffff;
}
.tipo_video > p{
    width: 100px;
    font-size: 12px;
    padding: 2px 0px;
    text-align: center;
    background: #64C5DD;
    color: #ffffff;
}
.tipo_classroom > p{
    width: 100px;
    font-size: 12px;
    padding: 2px 0px;
    text-align: center;
    background: #64C5DD;
    color: #ffffff;
}
.tipo_talleres > p{
    width: 100px;
    font-size: 12px;
    padding: 2px 0px;
    text-align: center;
    background: #F49A3F;
    color: #ffffff;
}
.tipo_cursos > p{
    width: 100px;
    font-size: 12px;
    padding: 2px 0px;
    text-align: center;
    background: #F49A3F;
    color: #ffffff;
}
.tipo_diplomados > p{
    width: 100px;
    font-size: 12px;
    padding: 2px 0px;
    text-align: center;
    background: #F49A3F;
    color: #ffffff;
}
/* fin - etiqueta curso */

/* efecto */
.imagen-hover-3{
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    width: 100%;
    overflow: hidden;
}
.contenedor-hover-3 img{
    width: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
}
.hover-3:hover .imagen-hover-3 img{
    transform: scale(1.1) ;
    transition: all 0.3s ease-in-out;
}
/* fin - efecto */

/* botones */
.boton_curso_d3 {
    margin: 20px 0px;
    font-weight: bold;
    width: 195px;
    background-color: #ffffff;
    border: solid 1px #707070;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    margin: 10px;
    text-align: center;
    color: #000000;
    padding: 10px;
    font-family: 'Montserrat';
}
.boton_curso_d3:hover{
    background-color: #707070;
    color: #ffffff;
    transition: all 0.5s ease-in-out;
}
.boton_curso_d3_active{
    margin: 20px 0px;
    font-weight: bold;
    width: 195px;
    background-color: #707070;
    border: solid 1px #707070;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    margin: 10px;
    padding: 10px;
    text-align: center;
    color: #ffffff;
}
.boton_curso_d3_active:focus{
    background-color: #707070;
}

.curso_boton_d3{
    /* height: 43px; */
    border: solid 2px #AE4DEB;
    background-color: #AE4DEB;
    color: #ffffff;
    font-weight: bold;
    padding: 10px 35px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}
.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #AE4DEB;
    transition: all 0.3s ease-in-out;
}

.boton_mostrar_cursos > button{
    /* margin-left: 45%; */
    font-weight: bold;
    background-color: #A80938;
    border: solid 1px #A80938;
    border-radius: 10px;
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px 30px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: 'Montserrat';
}
.boton_mostrar_cursos > button:hover{
    box-shadow: 0px 0px 15px #A80938;
    transition: all 0.3s ease-in-out;
}
.boton_mostrar_cursos > button p{
    margin-top: 12px;
    margin: 0px;
}
/* fin - botones */

/* flex */
.flex_d1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.flex_padre_2_d3{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
}

.hijo_2_d3{
    /* width: 25%; */
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
/* fin - flex */

/* fomurlario */
.busqueda_cursos{
    width: 585px;
    height: 45px;
}
.busqueda_cursos input{
    padding-left: 25px !important;
    width: calc(100% - 25px) !important;
}
.busqueda_cursos input::placeholder{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAQAAAC1QeVaAAAAxElEQVR4AXXPIUuDcRDA4Zv4AUSwTLEKVoNlySyCYLO98CCsahGrX0AW/QhmBRHEJghLS4JFBBXDgrJi2In78w8v4v3S8XDh4nd0DTxLEzd2RC2EnrH07trQVDrXqbhs7FvfnBDWDKWTigOpL2oWvZpYKPjio1zVHEt7BdOtaLUtHRX8MmqjRjooeCVttPBOWi+4JT1aLaDjVLpU/5ytn840Dj1IaWSpYmi8yVlTF54qh9K8nn27usJK5fC3yvfhPx7a/AE9dYwDEfKrlAAAAABJRU5ErkJggg==);
    background-position-y: 50%;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
    transition: left 0.4s, transform 0.4s;
    -webkit-background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAQAAAC1QeVaAAAAxElEQVR4AXXPIUuDcRDA4Zv4AUSwTLEKVoNlySyCYLO98CCsahGrX0AW/QhmBRHEJghLS4JFBBXDgrJi2In78w8v4v3S8XDh4nd0DTxLEzd2RC2EnrH07trQVDrXqbhs7FvfnBDWDKWTigOpL2oWvZpYKPjio1zVHEt7BdOtaLUtHRX8MmqjRjooeCVttPBOWi+4JT1aLaDjVLpU/5ytn840Dj1IaWSpYmi8yVlTF54qh9K8nn27usJK5fC3yvfhPx7a/AE9dYwDEfKrlAAAAABJRU5ErkJggg==);
    -webkit-background-position-y: 50%;
    -webkit-background-repeat: no-repeat;
    -webkit-transform: translate(-50%, -50%);
    -webkit-transition: left 0.4s, transform 0.4s;
    -moz-background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAQAAAC1QeVaAAAAxElEQVR4AXXPIUuDcRDA4Zv4AUSwTLEKVoNlySyCYLO98CCsahGrX0AW/QhmBRHEJghLS4JFBBXDgrJi2In78w8v4v3S8XDh4nd0DTxLEzd2RC2EnrH07trQVDrXqbhs7FvfnBDWDKWTigOpL2oWvZpYKPjio1zVHEt7BdOtaLUtHRX8MmqjRjooeCVttPBOWi+4JT1aLaDjVLpU/5ytn840Dj1IaWSpYmi8yVlTF54qh9K8nn27usJK5fC3yvfhPx7a/AE9dYwDEfKrlAAAAABJRU5ErkJggg==);
    -moz-background-position-y: 50%;
    -moz-background-repeat: no-repeat;
    -moz-transform: translate(-50%, -50%);
    -moz-transition: left 0.4s, transform 0.4s;
    -ms-background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAQAAAC1QeVaAAAAxElEQVR4AXXPIUuDcRDA4Zv4AUSwTLEKVoNlySyCYLO98CCsahGrX0AW/QhmBRHEJghLS4JFBBXDgrJi2In78w8v4v3S8XDh4nd0DTxLEzd2RC2EnrH07trQVDrXqbhs7FvfnBDWDKWTigOpL2oWvZpYKPjio1zVHEt7BdOtaLUtHRX8MmqjRjooeCVttPBOWi+4JT1aLaDjVLpU/5ytn840Dj1IaWSpYmi8yVlTF54qh9K8nn27usJK5fC3yvfhPx7a/AE9dYwDEfKrlAAAAABJRU5ErkJggg==);
    -ms-background-position-y: 50%;
    -ms-background-repeat: no-repeat;
    -ms-transform: translate(-50%, -50%);
    -ms-transition: left 0.4s, transform 0.4s;
    color: #b2b2b2;
    left: 50%;
    position: absolute;
    top: 50%;
    padding-left: 1.25em;
}
.busqueda_cursos input:focus::placeholder{
    left: 0.5em;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
}
/* fin - fomurlario */

.hijo_1_d3_nv{
    width: 650px;
}

.navegacion_cursos{
    border: solid 1px #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 10px;
    padding: 10px 50px;
    width: 70%;
    margin-bottom: 50px;
}
.navegacion_cursos b{
    cursor: default !important;
}
.navegacion_cursos div::after{
    content: ">";
    font-size: 16px;
    font-weight: bolder;
    margin: 0 10px;
}
.navegacion_ancho{
    width: fit-content;
}

.etiqueta-cursos{
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: rgba(22, 22, 22, 0.01) 0px -2px 7px 0px, rgba(80, 80, 80, 0.1) 0px 2px 10px 0px;
    border-radius: 15px;
    transition: all linear 0.3s;
    font-size: 11px;
}
.etiqueta-cursos:hover{
    box-shadow: rgb(22 22 22 / 18%) 0px -2px 7px 0px, rgb(80 80 80 / 10%) 0px 2px 10px 0px;
    transition: all linear 0.3s;
}

@media only screen and (min-width:200px) and (max-width:550px){
    .flex_d1{
        gap: 15px;
    }
    .flex_d1 > .s6{
        width: calc(50% - 15px) !important;
    }

    .hijo_2_d3{
        width: 42%;
    }

    .hijo_3_d3{
        width: 100%;
        padding: 10px;
    }

    .busqueda_cursos{
        width: 354px;
        height: 33px;
        margin-bottom: 3%;
    }

    .boton_precencial,
    .boton_hibrido,
    .boton_reunion,
    .boton_classroom,
    .boton_curso_d3{
        height: 50px;
        font-size: 12px;
    }

    .cuadro_curso{
        width: 45%;
    }
}
@media only screen and (min-width:551px) and (max-width:980px){
    .flex_d1{
        gap: 30px;
    }

    .hijo_1_d3_nv{
        width: 500px;
    }

    .hijo_3_d3{
        width: 33.333333%;
        padding: 10px;
    }

    .busqueda_cursos{
        width: 450px;
        height: 33px;
    }

    .boton_precencial,
    .boton_hibrido,
    .boton_reunion,
    .boton_classroom,
    .boton_curso_d3{
        height: 52px;
    }
}
@media only screen and (min-width:981px) and (max-width:1664px){
    .hijo_3_d3{
        width: 25%;
        padding: 10px;
    }

    .contenedor_curso{
        width: 80% !important;
    }
}
@media only screen and (min-width:1665px){
    .hijo_3_d3{
        width: 25%;
        padding: 10px;
    }
}


.etiquetas-cursos{
    margin: 0px !important;
    padding: 5px !important;
    border-radius: 10px;
}
.etiquetas-cursos > a{
    display: flex !important;
    justify-content: center !important;
}
.etiquetas-cursos > a > p{
    color: rgb(255, 255, 255) !important;
    font-size: 17px !important;
    font-weight: bolder !important;
    position: absolute !important;
}
.etiquetas-cursos img{
    transform: scale(1);
    transition: all linear 0.3s;
}
.etiquetas-cursos:hover img{
    transform: scale(1.2);
    transition: all linear 0.3s;
}

.etiquetas-cursos-2{
    margin: 0px !important;
    padding: 5px !important;
    border-radius: 10px;
}
.etiquetas-cursos-2 > a{
    display: flex !important;
    justify-content: center !important;
}
.etiquetas-cursos-2 > a > p{
    color: rgb(255, 255, 255) !important;
    font-size: 17px !important;
    font-weight: bolder !important;
    position: absolute !important;
}
.etiquetas-cursos-2 img{
    transform: scale(1);
    transition: all linear 0.3s;
}
.etiquetas-cursos-2:hover img{
    transform: scale(1.2) translateY(-10px);
    transition: all linear 0.3s;
}

.carousel.carousel-slider{
    margin-bottom: 10px;
}

/* nuevo css */
/* botones */
.boton_modalidad{
    cursor: pointer;
    width: 150px;
    height: 100px;
    background-color: #ffffff;
    border: solid 2px #64C5DD;
    border-radius: 10px;
    color: #64C5DD;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    font-weight: bolder;
    transition: all linear 0.3s;
}
.boton_modalidad:hover{
    background-color: #64C5DD;
    color: #ffffff;
    transition: all linear 0.3s;
}
.boton_modalidad:hover svg path{
    fill: #ffffff;
    transition: all linear 0.3s;
}
.boton_modalidad:focus{
    background-color: #ffffff;
}
.boton_modalidad img{
    width: 25px;
}

.boton_formato{
    cursor: pointer;
    width: 150px;
    height: 100px;
    background-color: #ffffff;
    border: solid 2px #F49A3F;
    border-radius: 10px;
    color: #F49A3F;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    font-weight: bolder;
    transition: all linear 0.3s;
}
.boton_formato:hover{
    background-color: #F49A3F;
    color: #ffffff;
    transition: all linear 0.3s;
}
.boton_formato:hover svg path{
    fill: #ffffff;
    transition: all linear 0.3s;
}
.boton_formato:focus{
    background-color: #ffffff;
}
.boton_formato img{
    width: 25px;
}
/* fin - botones */
/* fin - nuevo css */

/* tooltip */
.material-tooltip{
    background-color: #ffffff;
    color: #000000;
    border-radius: 15px;
    box-shadow: rgba(22, 22, 22, 0.01) 0px -2px 7px 0px, rgba(80, 80, 80, 0.1) 0px 2px 10px 0px;
    /* left: 480px !important; */
}