

.banerSpam {
    min-width: 100vw;
    font-weight: bolder;
}

.body__inner-wrapper {
    height: 100%;
    max-height: 52px;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-inline: auto;
}

.outline__text {
    -webkit-text-stroke: 1px #FF007E;
    -webkit-text-fill-color: white;
}

.marquee {
    width: 100%;
    height: 52px;
    pointer-events: none;
    background: #FF007E;
    overflow: hidden;
}
.color-marquee-1 {
    pointer-events: all;
    background: #FF007E;
}

.marquee__inner-wrap {
    height: 100%;
    width: 100%;
}

.marquee a,
.marquee span {
    text-align: center;
    color: white;
    white-space: nowrap;
    line-height: 52px;
    padding: 0;
    font-size: 20px;
}
.marquee span{
    cursor: default;
    margin-left: 15px;
}
.marquee a{
    font-size: 18px;
}


@media only screen and (min-width:200px) and (max-width:980px) {

    /*----------Tablet----------*/
    .marquee {
        height: 42px;
    }

    .marquee a
    .marquee span {
        text-align: center;
        color: white;
        white-space: nowrap;
        line-height: 42px;
        padding: 0 100px;
        font-size: 13px;
    }

    .body__inner-wrapper {
        max-height: 42px;
    }

    .marquee a
    .marquee span {
        line-height: 42px;
    }
}

@media only screen and (min-width:981px) and (max-width:1280px) {
    .marquee a,
    .marquee span {
        font-size: 15px;
    }
}

@media only screen and (min-width:1281px) {
    .marquee a,
    .marquee span {
        font-size: 17px;
    }
}

.marquee__img {
    width: max(8rem, 12vw);
    height: 8rem;
    margin: 0 4vw;
    border-radius: 100rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #444;
    filter: grayscale(1);
}

.marquee__inner {
    height: 100%;
    width: fit-content;
    align-items: center;
    display: flex;
    position: relative;
    animation: marquee 25s linear infinite;
    will-change: transform;
}

.marquee__inner[data-columns='1'] {
    animation: marquee 15s linear infinite;
}

.marquee__inner[data-columns='2'] {
    animation: marquee 30s linear infinite;
}

.marquee__inner[data-columns='3'] {
    animation: marquee 45s linear infinite;
}

.marquee__inner[data-columns='4'] {
    animation: marquee 60s linear infinite;
}

.marquee__inner[data-columns='5'] {
    animation: marquee 75s linear infinite;
}

@keyframes marquee {
    to {
        transform: translateX(-50%);
    }
}

/* fin - segundo slider */