/* CSS Multiple Whatsapp Chat */
.whatsapp-name {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 0.5;
}
#whatsapp-chat {
    box-sizing: border-box !important;
    outline: none !important;
    position: fixed;
    width: 350px;
    border-radius: 10px;
    box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
    bottom: 90px;
    right: 30px;
    overflow: hidden;
    z-index: 99;
    animation-name: showchat;
    animation-duration: 1s;
    transform: scale(1);
}
.blantershow-chat {
    padding: 13px !important;
    background: #25D366;
    color: #404040;
    position: fixed;
    display: flex;
    font-weight: 400;
    justify-content: space-between;
    z-index: 98;
    bottom: 25px;
    right: 30px;
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
}
@media only screen and (min-width:200px) and (max-width:550px){
    .blantershow-chat {
        bottom: 15px;
        right: 10px;
    }
}
@media only screen and (min-width:551px) and (max-width:980px){
    .blantershow-chat {
        bottom: 15px;
        right: 10px;
    }
}

.header-chat {
    background: #009688;
    background: #095e54;
    color: #fff;
    padding: 20px;
}
.header-chat h3 {
    margin: 0 0 10px;
}
.header-chat p {
    font-size: 14px;
    line-height: 1.7;
    margin: 0;
}
.info-avatar {
    position: relative;
}
.info-avatar img {
    border-radius: 100%;
    width: 50px;
    float: left;
    margin: 0 10px 0 0;
}
a.informasi {
    padding: 20px;
    display: block;
    overflow: hidden;
    animation-name: showhide;
    animation-duration: 0.5s;
}
a.informasi:hover {
    background: #f1f1f1;
}
.info-chat span {
    display: block;
}
#get-label, span.chat-label {
    font-size: 12px;
    color: #888;
}
#get-nama, span.chat-nama {
    margin: 5px 0 0;
    font-size: 15px;
    font-weight: 700;
    color: #222;
}
#get-label, #get-nama {
    color: #fff;
}
span.my-number {
    display: none;
}
/* .blanter-msg {
    color: #444;
    padding: 20px;
    font-size: 12.5px;
    text-align: center;
    border-top: 1px solid #ddd;
} */

input#chat-input {
    background: #ffffff;
    border: none;
    font-family: "Arial", sans-serif;
    width: 100%;
    outline: none;
    resize: none;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 0 0 0 10px;
    margin: 0;
}
#send-it {
    cursor: pointer;
    width: 40px;
    font-weight: 700;
    padding: 10px 10px 0;
    background: #eee;
    border-radius: 0 0 10px 0;
}
#send-it svg {
    fill: #a6a6a6;
    height: 24px;
    width: 24px;
}
.first-msg {
    background: transparent;
    padding: 30px;
    text-align: center;
}
.first-msg span {
    background: #e2e2e2;
    color: #333;
    font-size: 14.2px;
    line-height: 1.7;
    border-radius: 10px;
    padding: 15px 20px;
    display: inline-block;
}
.start-chat .blanter-msg {
    display: flex;
}
#get-number {
    display: none;
}
.close-chat {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 15px;
    color: #fff;
    font-size: 30px;
}
@keyframes ZpjSY {
    0% {
        background-color: #b6b5ba;
   }
    15% {
        background-color: #111;
   }
    25% {
        background-color: #b6b5ba;
   }
}
@keyframes hPhMsj {
    15% {
        background-color: #b6b5ba;
   }
    25% {
        background-color: #111;
   }
    35% {
        background-color: #b6b5ba;
   }
}
@keyframes iUMejp {
    25% {
        background-color: #b6b5ba;
   }
    35% {
        background-color: #111;
   }
    45% {
        background-color: #b6b5ba;
   }
}
@keyframes showhide {
    from {
        transform: scale(0.5);
        opacity: 0;
   }
}
@keyframes showchat {
    from {
        transform: scale(0);
        opacity: 0;
   }
}
@media screen and (max-width: 480px) {
    #whatsapp-chat {
        width: auto;
        left: 5%;
        right: 5%;
        font-size: 80%;
   }
}
.hide {
    display: none;
    animation-name: showhide;
    animation-duration: 0.5s;
    transform: scale(1);
    opacity: 1;
}
.show {
    display: block;
    animation-name: showhide;
    animation-duration: 0.5s;
    transform: scale(1);
    opacity: 1;
}
.whatsapp-message-container {
    display: flex;
    z-index: 1;
}
.whatsapp-message {
    padding: 7px 14px 6px;
    background-color: #fff;
    border-radius: 0px 8px 8px;
    position: relative;
    transition: all 0.3s ease 0s;
    opacity: 0;
    transform-origin: center top 0px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
    margin-top: 4px;
    margin-left: -54px;
    max-width: calc(100% - 66px);
}
.whatsapp-chat-body {
    padding: 20px 20px 20px 10px;
    background-color: #e6ddd4;
    position: relative;
}
.whatsapp-chat-body::before {
    display: block;
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 0.08;
    background-image: url("https://elfsight.com/assets/chats/patterns/whatsapp.png");
}
.dAbFpq {
    display: flex;
    z-index: 1;
}
.eJJEeC {
    background-color: #fff;
    width: 52.5px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    margin-left: 10px;
    opacity: 0;
    transition: all 0.1s ease 0s;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
}
.hFENyl {
    position: relative;
    display: flex;
}
.ixsrax {
    height: 5px;
    width: 5px;
    margin: 0px 2px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 0px;
    background-color: #9e9da2;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ZpjSY;
}
.dRvxoz {
    height: 5px;
    width: 5px;
    margin: 0px 2px;
    background-color: #b6b5ba;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    top: 0px;
    animation-name: hPhMsj;
}
.kAZgZq {
    padding: 7px 14px 6px;
    background-color: #fff;
    border-radius: 0px 8px 8px;
    position: relative;
    transition: all 0.3s ease 0s;
    opacity: 0;
    transform-origin: center top 0px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
    margin-top: 4px;
    margin-left: -54px;
    max-width: calc(100% - 66px);
}
.kAZgZq::before {
    position: absolute;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    top: 0px;
    left: -12px;
    width: 12px;
    height: 19px;
}
.bMIBDo {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.4);
}
.iSpIQi {
    font-size: 14px;
    line-height: 19px;
    margin-top: 4px;
    color: #111;
}
.iSpIQi {
    font-size: 14px;
    line-height: 19px;
    margin-top: 4px;
    color: #111;
}
.cqCDVm {
    text-align: right;
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(17, 17, 17, 0.5);
    margin-right: -8px;
    margin-bottom: -4px;
}
