.titulo_error_1{
    font-size: 35px;
    color: #00496C;
    margin: 0px;
    font-weight: bold;
}
.titulo_error_2{
    font-size: 75px;
    color: #00496C;
    margin: 0px;
    margin-top: -35px;
    font-weight: bold;
  }
  .titulo_error_3{
    font-size: 60px;
    color: #00496C;
    margin: 0px;
    font-weight: bold;
  }
  .texto_error_1{
    font-size: 20px;
    margin: 0px;
  }