/*----------Botones----------*/
.boton_1_d3{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    /* width: 170px; */
    height: 35px;
    padding: 6px 27px;
    background-color: #752884;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.boton_1_d3 p{
    font-weight: bold;
    font-size: 13px;
    color: white;
    margin: 0px;
}
.boton_1_d3:hover{
    box-shadow: 0px 0px 15px #752884;
    transition: all 0.5s ease-in-out;
}
.boton_1_d3 button{
    background: transparent;
    border: none;
    text-align: center;
    width: 100%;
}

.boton_2_d3{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    /* width: 170px; */
    height: 35px;
    padding: 6px 27px;
    background-color: #2D9495;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.boton_2_d3 p{
    font-weight: bold;
    font-size: 13px;
    color: white;
    margin: 0px;
}
.boton_2_d3:hover{
    box-shadow: 0px 0px 15px #2D9495;
    transition: all 0.5s ease-in-out;
}
.boton_2_d3 button{
    background: transparent;
    border: none;
    text-align: center;
    width: 100%;
}

.boton_3_d3{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    /* width: 170px; */
    height: 35px;
    padding: 6px 27px;
    background-color: #072474;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.boton_3_d3 p{
    font-weight: bold;
    font-size: 13px;
    color: white;
    margin: 0px;
}
.boton_3_d3:hover{
    box-shadow: 0px 0px 15px #072474;
    transition: all 0.5s ease-in-out;
}
.boton_3_d3 button{
    background: transparent;
    border: none;
    text-align: center;
    width: 100%;
}

.boton_4_d3{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    /* width: 170px; */
    height: 35px;
    padding: 6px 27px;
    background-color: #D75918;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.boton_4_d3 p{
    font-weight: bold;
    font-size: 13px;
    color: white;
    margin: 0px;
}
.boton_4_d3:hover{
    box-shadow: 0px 0px 15px #D75918;
    transition: all 0.5s ease-in-out;
}
.boton_4_d3 button{
    background: transparent;
    border: none;
    text-align: center;
    width: 100%;
}
/*----------Fin Botones----------*/



/*----------Box----------*/
.box_titulacion_iconos{
    height: 270px;
}
/*----------Fin Box----------*/



/*----------Contenedores----------*/
.contenedor_titulacion_primario{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.contenedor_7{
    padding: 0px 16%;
}
/*----------Fin Contenedores----------*/



/*----------Flex----------*/
.flex_padre_titulacion_iconos{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    align-items: center;
}

.flex_padre_curso_4{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.flex_hijo_5{
    margin: 0px 10px;
}
/*----------Fin Flex----------*/



/*----------Fondo----------*/
.fondo_titulacion{
    background-color: rgb(244, 244, 244);
}
/*----------Fin Fondo----------*/



.imagen_titulacion{
    width: 50px;
    height: 50px;
    padding: 0px 0%;
}
/*----------Fin Imagenes----------*/



/*----------Titulos y textos----------*/
.titulacion_titulo{
    font-size: 55px;
    font-family: 'Montserrat-Bold';
    color: black;
}

.titulacion_texto_general{
    font-size: 34px;
    font-family: 'Montserrat-Bold';
    font-size: 29px;
    margin: 0px;
    line-height: 35px;
}

/* .texto_5{
    font-size: 15px;
    padding: 0px 3%;
} */
/*----------Fin Titulos y textos----------*/

.contenedor_2_titulacion{
    width: 218px;
}

.texto_titulacion_punto::before{
    content: url("../../Images/Icon/56.png");
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -10px;
    margin-top: -3px;
    padding-bottom: 5px;
    position: absolute;
}

@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/
    .contenedor_titulacion{
        width: 315px;
    }

    .box_titulacion_iconos{
      height: 100%;
      text-align: center;
    }

    .contenedor_titulacion_primario{
        padding: 0 15px;
    }

    .contenedor_titulacion{
        padding: 0;
        margin-bottom: 50px;
    }

    .contenedor_7{
        padding: 0%;
    }

    .flex_padre_titulacion_iconos{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 40px;
        flex-direction: column;
    }

    .imagen_titulacion{
        width: 37px;
        height: 37px;
    }

    .d2_texto_general{
        font-size: 14px;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .contenedor_titulacion{
        width: 500px;
    }

    .contenedor_1_titulacion{
        min-width: 500px;
        max-width: 600px;
    }

    .flex_padre_titulacion_iconos{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .box_titulacion_iconos{
        height: 390px;
    }

    .contenedor_7{
        padding: 0px;
    }

    /* .texto_5{
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        margin: 0px;
        margin-top: 5px;
        padding: 0px;
    } */
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .contenedor_titulacion{
        width: 800px;
    }

    .contenedor_1_titulacion{
        min-width: 800px;
        max-width: 1000px;
    }

    .contenedor_7{
        padding: 0px 5%;
    }
}

@media only screen and (min-width:1665px) {
    .contenedor_titulacion{
        width: 800px;
    }

    .contenedor_1_titulacion{
        min-width: 800px;
        max-width: 1000px;
    }
}