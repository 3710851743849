/* collapsible */
.collapsible_curso{
    border-radius: 20px;
    border: none;
    box-shadow: none;
}
.collapsible-header_curso{
    border-bottom: none;
    padding: 20px 40px !important;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    border-radius: 20px;
}
.collapsible-body_curso{
    color: rgb(255, 255, 255);
    border-radius: 0px 0px 20px 20px;
    padding: 0 40px 20px 40px;
}
/* fin - collapsible */

.inversiones{
    color: #fff;
    padding: 20px 40px;
    border-radius: 20px;
    margin-bottom: 35px;
}
.inversiones p{
    margin: 0px;
}

.pagar_curso{
    background-color: #3B99C5;
    color: #fff;
    padding: 20px 40px;
    border-radius: 20px;
    margin-bottom: 35px;
}
.pagar_curso  p{
    margin: 0px;
}

/* inicio - fixme cursos */

.tarjeta_fixme{
    background-color: #fff;
    border-radius: 20px;
    margin-top: 50px;
}
.seccion_curso_fixme{
    padding: 10px 30px;
}

.tarjeta_fixme .divider{
    background-color: #d9d9d9 !important;
}

.etiqueta{
    background-color: #B62B93;
    color: #fff;
    font-weight: bold;
    padding: 0 10px 0 10px;
    margin: 5px 0;
    cursor: pointer;
    font-size: 10px;
    display: flex;
    height: 20px;
    align-items: center;
}
.etiqueta_fin{
    background-color: #B62B93;
    color: #B62B93;
    clip-path: polygon(100% 0%, 70% 50%, 100% 100%, 0 100%, 0 0);
    padding: 0 10px;
    position: relative;
    left: -2px;
    top: -0.5px;
    height: 20.05px;
    margin: 0;
}

.etiqueta_profesor{
    background-color: #B62B93;
    color: #fff;
    font-weight: bold;
    padding: 0 10px 0 10px;
    margin: 5px 0;
    cursor: pointer;
    font-size: 15px;
    display: flex;
    height: 25px;
    align-items: center;
}
.etiqueta_fin_profesor{
    background-color: #B62B93;
    color: #B62B93;
    clip-path: polygon(100% 0%, 70% 50%, 100% 100%, 0 100%, 0 0);
    padding: 0 10px;
    position: relative;
    left: -2px;
    top: -0.5px;
    height: 25.05px;
    margin: 0;
}

.icono_texto{
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    left: -20px;
}
.icono_texto img{
    width: 15px;
}
/* fin - fixme cursos */

.boton_curso{
    background: #00B5C1;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 7px;
    min-width: 15px;
    box-shadow:0px 15px 10px -10px rgba(0,0,0,0.4);
    transition:all ease-in-out 300ms;
}

.boton_curso:hover{ 
    box-shadow:0px 35px 19px -20px rgba(0,0,0,0.2);
    transform:translate(0px,-10px);
}

strong{
    font-weight: bold;
}

/* El contenedor con el padding-top crea el tamaño del vídeo */
.contenedor_video{
    height:0px;
    width:100%;
    padding-top:56.25%; /* Relación: 16/9 = 56.25% */
    position:relative;
}
/* El iframe se adapta al tamaño del contenedor */
.video{
    position:absolute;
    height:100%;
    width:100%;
    top:0px;
    left:0px;
}

.ancho_imagen_curso{
    width: 90%;
    margin: auto;
}

.sombra_etiqueta_movil{
    box-shadow: 0px -5px 50px -10px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0px -5px 50px -10px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px -5px 50px -10px rgba(0,0,0,0.4);
    position: fixed;
    background: rgb(255, 255, 255);
    width: 100%;
    left: 0;
    bottom: 0;
}

/* .modal-overlay{
    display: none !important;
} */


/* pop pop efectos */
.contenedor_pop_curso{
    position: fixed;
    z-index: 8999;
    height: 100vh;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000c7;
    cursor: pointer;
}
.pop_curso{
    width: 50%;
    background: #fff;
    padding: 50px;
    border-radius: 20px;
    cursor: auto;
    position: fixed;
    z-index: 9999;
    max-height: 80vh;
    overflow: auto;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-name: aparecer;
}
@keyframes aparecer {
    0% {
        filter: opacity(0);
   }
    50% {
        filter: opacity(0.5);
   }
    100% {
        filter: opacity(1);
   }
}
.pop_salir::before{
    content: "x";
    color: #707070;
    font-size: 50px;
    position: absolute;
    left: 92%;
    top: 0%;
    cursor: pointer;
}

.titulo_pop{
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-size: 30px;
    font-weight: bold;
}
/* pop pop efectos */



/* efecto scroll etiqueta sticky */
.invisible_curso_etiqueta{
    opacity: 0;
    transition: all linear 0.3s;
}

.visible_curso_etiqueta_sticky{
    opacity: 1;
    transition: all linear 0.3s;
    position: sticky;
    top: 155px;
    z-index: 999;
}
.visible_curso_etiqueta_sticky .tarjeta_fixme{
    box-shadow: rgba(22, 22, 22, 0.01) 0px -2px 7px 0px, rgba(80, 80, 80, 0.1) 0px 2px 10px 0px;
}
/* fin - efecto scroll etiqueta sticky */


.arrow_curso{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow_curso::after{
    content: "";
    background-image: url(../../../Images/Icon/63.png);
    background-repeat: no-repeat;
    transform: rotate(180deg);
    background-size: 20px;
    height: 20px;
    width: 20px;
}
.active .arrow_curso::after{
    content: "";
    background-image: url(../../../Images/Icon/63.png);
    background-repeat: no-repeat;
    background-size: 20px;
    transform: rotate(360deg) !important;
    margin-top: 5px;
    height: 20px;
    width: 20px;
}


@media only screen and (min-width:200px) and (max-width:550px){
    .navegacion_cursos_principal{
        display: none !important;
    }

    .pop_curso{
        width: 90%;
        background: #fff;
        padding: 50px;
        border-radius: 20px;
        cursor: auto;
    }
    .pop_salir::before{
        content: "x";
        color: #707070;
        font-size: 40px;
        position: absolute;
        left: 85%;
        top: 0%;
        cursor: pointer;
    }

    .titulo_pop{
        font-size: 20px;
    }

    .etiqueta_fin{
        top: 0.5px;
    }

    .informacion_curso h4{
        font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        font-size: 17px;
        font-weight: bold;
    }

    .ancho_imagen_curso{
        width: 100%;
        margin: auto;
    }

    .encabezadoPrecio{
        font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        font-size: 20px;
    }

    .boton_curso_movil{
        background: #00B5C1;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        gap: 7px;
    }
}
@media only screen and (min-width:551px) and (max-width:980px){
    .navegacion_cursos_principal{
        display: none !important;
    }

    .informacion_curso h4{
        font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        font-size: 20px;
        font-weight: bold;
    }

    .encabezadoPrecio{
        font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        font-size: 20px;
    }
}
@media only screen and (min-width:981px) and (max-width:1664px){
    .navegacion_sticky[data-className="false"]{
        top: 133px;
    }

    .etiqueta_fin{
        top: 0.5px;
    }

    .padding_right_curso{
        padding-right: 50px !important;
    }

    .etiqueta_fin_profesor{
        top: 0px;
    }

    .contenedor_grande_curso{
        width: 85%;
        max-width: 1200px;
    }

    .informacion_curso h4{
        font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        font-size: 20px;
        font-weight: bold;
    }

    .encabezadoPrecio{
        font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        font-size: 20px;
    }
}
@media only screen and (min-width:1665px){
    .navegacion_sticky[data-className="false"]{
        top: 125px;
    }

    .padding_right_curso{
        padding-right: 50px !important;
    }

    .contenedor_grande_curso{
        width: 80%;
        max-width: 1300px;
        max-width: 1200px;
    }

    .etiqueta_fin{
        top: 0.5px;
    }

    .informacion_curso h4{
        font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        font-size: 20px;
        font-weight: bold;
    }

    .encabezadoPrecio{
        font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        font-size: 20px;
    }
}

.navegacion_cursos_principal{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    padding: 10px 50px;
    width: 100%;
    margin-top: -7px;
    position: sticky;
    top: 135px;
}
.navegacion_cursos_principal b{
    color: #ffffff;
    cursor: default;
}
.navegacion_cursos_principal a{
    color: #ffffff;
}
.navegacion_cursos_principal div::after{
    content: ">";
    color: #ffffff;
    font-size: 16px;
    font-weight: bolder;
    margin: 0 10px;
}
.navegacion_sticky{
    position: sticky;
    z-index: 1000;
}
.navegacion_sticky[data-className="true"]{
    top: 184px;
}


/* cursos colores */
.color_defecto{
    color: #00496c;
}
.color_contenedor_defecto h4{
    color: #00496c;
}
.background_defecto{
    background: #00496c;
    border: solid 2px #00496c;
}
.background_defecto.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #00496c !important;
    transition: all 0.3s ease-in-out;
}

.color_1{
    color: #F2BE14;
}
.color_contenedor_1 h4{
    color: #F2BE14;
}
.background_1{
    background: #F2BE14;
    border: solid 2px #F2BE14;
}
.background_1.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #F2BE14 !important;
    transition: all 0.3s ease-in-out;
}

.color_2{
    color: #FB4E00;
}
.color_contenedor_2 h4{
    color: #FB4E00;
}
.background_2{
    background: #FB4E00;
    border: solid 2px #FB4E00;
}
.background_2.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #FB4E00 !important;
    transition: all 0.3s ease-in-out;
}

.color_3{
    color: #D21D0A;
}
.color_contenedor_3 h4{
    color: #D21D0A;
}
.background_3{
    background: #D21D0A;
    border: solid 2px #D21D0A;
}
.background_3.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #D21D0A !important;
    transition: all 0.3s ease-in-out;
}

.color_4{
    color: #FBA709;
}
.color_contenedor_4 h4{
    color: #FBA709;
}
.background_4{
    background: #FBA709;
    border: solid 2px #FBA709;
}
.background_4.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #FBA709 !important;
    transition: all 0.3s ease-in-out;
}

.color_5{
    color: #A02BC1;
}
.color_contenedor_5 h4{
    color: #A02BC1;
}
.background_5{
    background: #A02BC1;
    border: solid 2px #A02BC1;
}
.background_5.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #A02BC1 !important;
    transition: all 0.3s ease-in-out;
}

.color_6{
    color: #BE56A9;
}
.color_contenedor_6 h4{
    color: #BE56A9;
}
.background_6{
    background: #BE56A9;
    border: solid 2px #BE56A9;
}
.background_6.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #BE56A9 !important;
    transition: all 0.3s ease-in-out;
}

.color_7{
    color: #FF69B4;
}
.color_contenedor_7 h4{
    color: #FF69B4;
}
.background_7{
    background: #FF69B4;
    border: solid 2px #FF69B4;
}
.background_7.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #FF69B4 !important;
    transition: all 0.3s ease-in-out;
}

.color_8{
    color: #E44F9C;
}
.color_contenedor_8 h4{
    color: #E44F9C;
}
.background_8{
    background: #E44F9C;
    border: solid 2px #E44F9C;
}
.background_8.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #E44F9C !important;
    transition: all 0.3s ease-in-out;
}

.color_9{
    color: #0BBCCC;
}
.color_contenedor_9 h4{
    color: #0BBCCC;
}
.background_9{
    background: #0BBCCC;
    border: solid 2px #0BBCCC;
}
.background_9.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #0BBCCC !important;
    transition: all 0.3s ease-in-out;
}

.color_10{
    color: #E81B75;
}
.color_contenedor_10 h4{
    color: #E81B75;
}
.background_10{
    background: #E81B75;
    border: solid 2px #E81B75;
}
.background_10.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #E81B75 !important;
    transition: all 0.3s ease-in-out;
}

.color_11{
    color: #117ACD;
}
.color_contenedor_11 h4{
    color: #117ACD;
}
.background_11{
    background: #117ACD;
    border: solid 2px #117ACD;
}
.background_11.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #117ACD !important;
    transition: all 0.3s ease-in-out;
}

.color_12{
    color: #2898EE;
}
.color_contenedor_12 h4{
    color: #2898EE;
}
.background_12{
    background: #2898EE;
    border: solid 2px #2898EE;
}
.background_12.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #2898EE !important;
    transition: all 0.3s ease-in-out;
}

.color_13{
    color: #FD8306;
}
.color_contenedor_13 h4{
    color: #FD8306;
}
.background_13{
    background: #FD8306;
    border: solid 2px #FD8306;
}
.background_13.curso_boton_d3:hover{
    box-shadow: 0px 0px 15px #FD8306 !important;
    transition: all 0.3s ease-in-out;
}
/* fin - cursos colores */