.boton_licenciatura_1{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    height: 43px;
    background-color: #752884;
    border: solid 1px #752884;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    padding: 0px 10px;
    font-family: 'Montserrat';
}
.boton_licenciatura_1:hover{
    box-shadow: 0px 0px 15px #752884;
    transition: all 0.5s ease-in-out;
}
.boton_licenciatura_1:focus{
    background-color: #752884;
}

/* .contenedor_d3{
    padding: 15px 10%;
}

.contenedor_2_d3{
    padding: 30px 20%;
}

.contenedor_lic_plan{
    padding: 15px 10%;
}

.flex_padre_cine{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.flex_1{
    width: 320px;
}

.flex_2_d3{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
} */
/* 
.hijo_1_d3 {
    padding: 0px 0px 30px 0px;
}

.logo_yo_creo{
    width: 150px;
}

.imagen_lic{
    width: 50px;
}

.titulo_licenciatura{
    margin-top: 10px;
} */

/* .texto_3{
    font-family: "Montserrat";
    font-size: 14px;
} */

.texto_licenciatura_campo_trabajo_14_a{
    width: 608px;
    font-size: 13px;
}

.texto_licenciatura_campo_trabajo_15{
    width: 322px;
    font-size: 14px;
}
.texto_licenciatura_campo_trabajo_15 > ul > li::before{
    content: url("../../../Images/Icon/56.png");
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -10px;
    margin-top: -3px;
    padding-bottom: 5px;
    position: absolute;
}

.texto_lic_2{
    font-size: 42px;
    font-Weight: bold;
    margin-top: 25px;
    margin-bottom: 33px;
}

.numero_d3_a{
    font-family: 'Jost-ExtraBold';
    font-size: 42px;
    color: #B612C5;
    padding: 10px 0px !important;
}

.puntos_1_nv{
    line-height: 25px;
}
.puntos_1_nv > ul{
    margin-left: 15px;
}
.puntos_1_nv > ul > li::before{
    content: url("../../../Images/Icon/56.png");
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -10px;
    margin-top: -3px;
    padding-bottom: 5px;
    position: absolute;
}



@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/
    .texto_licenciatura_campo_trabajo_15{
        font-size: 13px;
    }
    .texto_licenciatura_campo_trabajo_15 > p{
        margin: 0px;
    }
    
    .boton_licenciatura_1{
        display: flex;
        color: #ffffff;
        margin-top: 30px;
        font-size: 13px;
        font-weight: bold;
        height: 43px;
        background-color: #752884;
        border: solid 1px #752884;
        border-radius: 10px;
        transition: all 0.5s ease-in-out;
    }
    .boton_licenciatura_1{
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .contenedor_habilidades_a{
        text-align: left;
        padding: 0px 0px 0px 15px;
    }

    /* .contenedor_2_d3{
        padding: 20px 15%;
    }

    .contenedor_lic_plan{
        padding: 15px 15%;
    }

    .flex_movil_2{
        padding: 0 0px 0 15px;
    }

    .flex_padre_cine{
        justify-content: center;
    }

    .flex_1{
        width: 100%;
    } */

    /* .hijo_1_d3{
        width: 500px;
    }

    .logo_yo_creo{
        width: 65px;
        margin: 0;
    }

    .imagen_lic{
        width: 28px;
        margin-top: 10px;
    } */

    /* .d3_licenciatura{
        font-size: 40px !important; 
        margin: 10px 0px 0px !important;
    } */

    .titulo_lic_perfil{
        font-size: 13px;
    }

    .texto_licenciatura_campo_trabajo_14_a{
        width: 315px;
        font-size: 13px;
    }

    .texto_lic_2{
        font-size: 17px;
        font-Weight: bold;
        margin-top: 25px;
        margin-bottom: 33px;
    }

    .numero_d3_a{
        font-size: 20px;
    }

    .altura_video_licenciatura{
        height: 220px;
    }

    .movil_warp{
        flex-wrap: wrap;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .boton_licenciatura_1{
        display: flex;
        color: #ffffff;
        margin-top: 30px;
        font-size: 13px;
        font-weight: bold;
        height: 43px;
        background-color: #752884;
        border: solid 1px #752884;
        border-radius: 10px;
        transition: all 0.5s ease-in-out;
    }

    .texto_licenciatura_campo_trabajo_15{
        width: 200px;
        font-size: 13px;
    }

    .hijo_1_d3{
        width: 33.33333%;
    }

    .altura_video_licenciatura{
        height: 400px;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .hijo_1_d3{
        width: 33.33333%;
    }

    .altura_video_licenciatura{
        height: 500px;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/
    .hijo_1_d3{
        width: 33.33333%;
    }

    .altura_video_licenciatura{
        height: 600px;
    }
}