/* etiquetas */
.etiqueta-blog-grande p{
    margin: 0;
}
.etiqueta-blog-grande img{
    width: 100%;
    border-radius: 15px;
}

.etiqueta-blog-chico 
.contenedor-imagen-blog{
    width: 100%;
    overflow: hidden;
}
.etiqueta-blog-chico img {
    height: 100%;
    max-height: 150px;
}

.etiqueta-curso{
    background-color: #00496c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: fit-content;
}
.etiqueta-curso p{
    color: #ffffff;
    margin: 0;
    padding: 7px 20px;
}
.etiqueta-curso:focus{
    background-color: #00496c;
}


.etiqueta-curso-2{
    background-color: #EBEBEB;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: fit-content;
}
.etiqueta-curso-2 p {
    padding: 7px 20px;
    margin: 0;
    color: #646D7C;
}
.etiqueta-curso-2:focus{
    background-color: #EBEBEB;
}
/*  fin - etiquets */


/* textos */

.licenciatura-hastag{
    font-size: 13px;
    color: #A80B38;
    font-weight: bolder;
}

.fecha-blog-etiqueta{
    font-size: 12px;
    color: #A4A8B0;
}
/* fin - textos */


/* botones */
.boton-licenciatura-blog{
    margin: 5px 0;
}
.boton-licenciatura-blog button{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00496c;
    border-radius: 10px;
    color: #ffffff;
    height: 40px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.boton-licenciatura-blog-2{
    margin: 5px 0;
}
.boton-licenciatura-blog-2 button{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: solid 2px #ebebeb;
    color: #000000;
    height: 40px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
}

.boton-etiqueta-blog{
    background: transparent;
    border: solid 1px #ebebeb;
    font-size: 15px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}
.boton-etiqueta-blog:focus{
    background: transparent;
}
/* fin - botones */

@media only screen and (min-width:200px) and (max-width:550px){
    .etiqueta-blog-chico img {
        clip-path: inset(0 20% 20% 0);
    }
}
@media only screen and (min-width:551px) and (max-width:980px){
    .etiqueta-blog-chico img {
        clip-path: inset(0 20% 0 0);
    }
}
@media only screen and (min-width:981px) and (max-width:1664px){
    .etiqueta-blog-chico img {
        clip-path: inset(0 20% 20% 0);
    }
}
@media only screen and (min-width:1665px){
    .etiqueta-blog-chico img {
        clip-path: inset(0 20% 0 0);
    }
}