.boton_7{
    font-size: 14px;
    height: 43px;
    width: 200px;
    margin: 20px;
    background-color: #A80938;
    border: solid 1px #A80938;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.boton_7:hover{
    box-shadow: 0px 0px 15px #A80938;
    transition: all 0.5s ease-in-out;
}
.boton_7 p{
    margin: 10px;
    text-align: center;
    color: white;
    transition: all 0.5s ease-in-out;
}

.caja_6{
    width: 600px;
}

.caja_13{
    width: 1190px;
}

.caja_14{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 1000px;
    margin-bottom: 30px;
}

.caja_15{
    width: 750px;
    margin: 20px 0px;
}

.caja_18{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 10px;
}

.caja_19{
    width: 395px;
    margin-top: 40px;
}

.caja_20{
    margin-top: 20px;
    width: 370px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.box_10_becas{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 265px;
}
.box_10_becas_2{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 205px;
}
.box_2{
    width: 375px;
}
.box_2 span{
    font-weight: bold;
}

.box_14{
    width: 650px;
    margin: 20px 0px;
}

.box_15{
    margin: 20px 0px;
}

.box_19{
    width: 720px;
    margin: 10px 0px 20px;
}

.flex_padre_becas{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 920px;
}

.flex_4{
    width: 50%;
}

.flex_padre_5{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    align-items: center;
}

.flex_padre_7{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.fondo_3{
    margin-bottom: 0px;
    background-color: #00496C;
    color: #fff;
    padding: 5px;
}

.fondo_8{
    margin-bottom: 50px;
    background-color: #00496C;
    color: #fff;
    width: 1045px;
}

.imagen_becas{
    width: 100%;
}

.icon_becas{
    margin-right: 15px;
    width: 70px;
    height: 70px;
}

.icon_becas_2{
    width: 54px;
    height: 54px;
}

.titulo_2_becas{ 
    font-size: 17px;
    font-weight: bold;
}

.titulo_6{
    font-size:50px;
     color:#A80938;
     margin:0px 3%;
     font-family: 'Jost-ExtraBold';
}

.titulo_8{
    font-size: 36px;
}

.titulo_11{
    font-size: 34px;
    font-family: 'Montserrat-Bold';
    color: #00496C;
    margin: 20px 0px;
    line-height: 35px;
}

.titulo_12_becas{
    font-size: 25px;
    font-weight: bold;
    margin: 18px 0px;
}

/* .texto_3{
    font-family: "Jost";
    font-size: 17px;
} */

.texto_4{
    font-size: 20px;
}

.becas iframe{
    width: 626px;
    height: 474PX;
}

.becas_2 iframe{
    width: 330px;
    height: 232PX;
    margin: 0px 25px;
}
.becas_2 p{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
}


.contenedor_1_becas{
    width: 217px;
}

@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/
    .boton_7{
        height: auto !important;
    }
    
    .caja_6{
        width: 300px;
    }

    .caja_13{
        width: 260px;
    }

    .caja_14{
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .caja_15{
        width: 210px;
    }

    .caja_18{
        justify-content: center;
        margin: 0px;
    }

    .caja_19{
        margin-top: 0PX;
        width: 280px;
    }

    .caja_21{
        width: 140px;
    }

    .box_2{
        width: 237px;
        text-align: center;
    }
    .box_2 span{
        font-weight: normal;
    }
    .box_2 b{
        font-size: 14px;
        font-weight: bold;
    }
    .box_2 .titulo_8{
        font-size: 22px;
    }

    .box_10_becas{
        text-align: center;
        margin: 0px;
        width: 250px;
    }

    .box_14{
        width: 225px;
        margin: 0px;
    }

    .box_15{
        display: flex;
        justify-content: center;
        width: 140px;
        margin: 0px;
    }

    .box_19{
        width: 270px;
        margin: 0px;
    }
    
    .flex_padre_becas{
        justify-content: center;
    }

    .flex_2{
        width: 286px;
    }

    .flex_4{
        width: 100%;
    }

    .flex_padre_5{
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .fondo_3{
        padding: 20px 0px;
        width: 100%;
        margin-bottom: 0px;
    } 

    .fondo_8{
        width: 320px;
    }

    .icon_becas{
        width: 40px;
        height: 40px;
        margin: 0%;
    }

    .icon_becas_2{
        margin: 0px;
    }

    .titulo_2_becas{
        font-size: 12px;
    }

    .titulo_6{
        font-size: 30px;
    }

    .titulo_8{
        font-size: 22px;
    }

    .texto_4{
        font-size: 12px;
    }

    .titulo_12_becas{
        font-size: 17px;
        font-weight: bold;
        margin: 18px 0px;
    }    

    .becas iframe{
        width: auto;
        height: 274PX;
    }
    .becas p{
        margin: 0%;
    }

    .becas_2 iframe{
        width: auto;
        height: 232PX;
        margin: 0px;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .boton_7 p{
        margin: 0px;
    }
    
    .titulo_6{
        font-size: 45px;
    }

    .titulo_8{
        font-size: 30px;
    }

    .titulo_12_becas{
        font-size: 17px;
        font-weight: bold;
        margin: 18px 0px;
    } 

    .becas iframe{
        width: 286px;
        height: 150px;
    }
    .becas p{
        margin: 0%;
    }

    .becas_2 iframe{
        width: 286px;
        height: 150px;
        margin: 0px;
        padding: 0px 10px;
    }
    .becas_2 p{
        font-size: 15px;
    }

    .caja_14{
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .box_2{
        width: 237px;
    }
    .box_2 span{
        font-weight: normal;
    }
    .box_2 b{
        font-size: 14px;
        font-weight: bold;
    }
    .box_2 .titulo_8{
        font-size: 22px;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .flex_padre_becas{
        padding: 0px 6%;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/

}