.header_calendario{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}

.flecha{
    font-size: 30px;
    font-weight: bold;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.izq{
    background-image: url(../../Images/Icon/55.png);
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
}
.dec{
    background-image: url(../../Images/Icon/55.png);
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
}

.calendario_dias{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: #ffffff;
}
.dia{
    transition: all 0.3s ease-in-out;
    width: 14.28571428%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    cursor: pointer;
}
.dia:hover{
    transition: all 0.3s ease-in-out;
    background: #dddddd;
}
.dia_vacio{
    width: 14.28571428%;
    height: 60px;
}

.calendario_semanas{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    cursor: default;
}
.dia_semana{
    width: 14.28571428%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid 2px #ffffff;
}
.dia_semana p{
    font-weight: bold;
    color: #92929D;
    margin: 0px;
}

.calendario_dia{
    border-right: solid 2px #ffffff;
    border-bottom: solid 2px #ffffff;
}
.calendario_lunes{
    border: solid 2px #ffffff;
    border-top: none;
}
.calendario_vacio{
    background: #ffffff;
}

.eventos > div {
    margin: 0px 35px;
    padding-bottom: 20px;
    border-bottom: solid 2px #E2E2EA;
}

.punto {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}





@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/
    .calendario_movil_bajo{
        margin-bottom: 0px;
    }
    
    .calendario{
        width: 100%;
    }
    
    .eventos{
        width: 100%;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .calendario{
        width: 100%;
    }
    
    .eventos{
        width: 100%;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .calendario{
        width: 60%;
    }
    
    .eventos{
        width: 40%;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/
    .calendario{
        width: 60%;
    }
    
    .eventos{
        width: 40%;
    }
}