.card-inscripcion{
    padding: 22px 30px;
    border-radius: 18px;
    background: #FFF;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    height: 330px;
    width: 313px;
    color: #6E6E73;
    cursor: default;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 5px;
}
.card-inscripcion p {
    user-select: none;
}
.card-inscripcion .titulo_4_nv {
    margin-top: 0px;
}

.in-fondo-4{
    background-color: #F0F2F6;
    padding: 20px 0;
}
.in-fondo-4 .styles-module_item-provider__YgMwz{
    width: 100% !important;
    /* margin-left: 5% !important; */
}

.contenedro-carrusle-1 .styles-module_item-tracker__3bypy{
    margin-left: 10%;
}

.contenedro-carrusle-1 .styles-module_carousel-base__3keqD > div > button{
    border: none;
    background-color: #d2d2d7;
    border-radius: 56px;
    position: absolute;
    top: 45%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: opacity(0);
    transition: all 0.3s linear;
    z-index: 999;
}
.contenedro-carrusle-1 .styles-module_carousel-base__3keqD .btn-izquierda{
    right: 10px !important;
}
.contenedro-carrusle-1 .styles-module_carousel-base__3keqD .btn-derecha{
    left: 10px !important;
}
.contenedro-carrusle-1 .styles-module_carousel-base__3keqD:hover > div > button{
    filter: opacity(1);
    transform: scale(1.2);
    transition: all 0.3s linear;
}
.contenedro-carrusle-1 .styles-module_carousel-base__3keqD svg{
    fill: #000000a3;
}

.rein-fondo-6 > .styles-module_sliderBase__swkx1 > .styles-module_slider__o0fqa{
    justify-content: center;
}

@media only screen and (min-width: 200px) and (max-width: 550px){
    .card-inscripcion{
        padding: 12px 10px;
        width: 250px;
        height: 350px;
    }

    .in-fondo-4 > .styles-module_sliderBase__swkx1{
        width: 100% !important;
    }

    .margin-left-ins{
        margin-left: 5%;
    }

    .rein-fondo-6 > .styles-module_sliderBase__swkx1 > .styles-module_slider__o0fqa{
        justify-content: start;
    }
}