/*----------Box----------*/
.box_1{
    width: 254px;
    line-height: 20px;
    margin: 0px 20px;
}

.box_1_2{
    width: 262px;
    line-height: 20px;
    margin: 0px 20px;
}
.box_1_3{
    width: 250px;
    line-height: 20px;
    margin: 0px 20px;
}
.box_1_4{
    width: 254px;
    line-height: 20px;
    margin: 0px 20px;
}
.box_1_5{
    width: 251px;
    line-height: 20px;
    margin: 0px 20px;
}
.box_1_6{
    width: 258px;
    line-height: 20px;
    margin: 0px 20px;
}

.box_11{
    width: 340px;
    margin: 0px;
}
.box_11 span{
    font-weight: bold;
}

.box_12{
    width: 395px;
}
/*----------Fin Box----------*/



/*----------Contenedores----------*/
.contenedor_mapa{
    width: 88%;
    margin: auto;
    height: 441px;
}

.contenedor_3{
    padding: 0px 25%;
}

.contenedor_6{
    margin: 20px;
    padding: 0px 30%;
}

.contenedor_hospedarte{
    padding: 0px 25%;
}
/*----------Fin Contenedores----------*/



/*----------Flex----------*/
.flex_padre_cerca_1{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}
/*----------Fin Flex----------*/



/*----------Fondos----------*/
.fondo_1{
    background-color: #f4f4f4;
    padding: 40px 0% 40px;
}
.fondo_4{
    background-color: #00496c;
    padding: 40px 0% 40px;
    color: #ffffff;
}
.fondo_9{
    background-color: #f4f4f4;
}
/*----------Fin Fondos----------*/



/*----------Imagenes----------*/
.imagen_cerca_caav{
    width: 100%;
}

.imagen_cerca_1{
    margin: 100px 0%;
    width: 167px;
    height: 39px;
}

.imagen_cerca_2{
    width: 167px;
    margin: 1px;
}

.imagen_cerca_3{
    width: 33%;
}
/*----------Fin Imagenes----------*/



/*----------Videos----------*/
.video_1{
    width: 50%;
    margin: auto;
    margin-bottom: 80px;
}
/*----------Fin Videos----------*/



/*----------Titulos y textos----------*/
.titulo_2{ 
    font-size: 20px;
    font-weight: bold;
}
/*----------Fin Titulos y textos----------*/



.entorno{
    width: 412px;
}
.entorno > .titulo_9{
    margin-top: 72px;
}



@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/

    .contenedor_chico_entorno{
        width: 65%;
    }
    
    /*----------Box----------*/
    .box_1{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        width: 280px;
        line-height: 15px !important;
    }

    .box_1_2{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        width: 270px;
        line-height: 15px !important;
    }

    .box_1_3{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        width: 280px;
        line-height: 15px !important;
    }

    .box_1_4{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        width: 282px;
        line-height: 15px !important;
    }

    .box_1_5{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        width: 254px;
        line-height: 15px !important;
    }

    .box_1_6{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        width: 280px;
        line-height: 15px !important;
    }
    
    .box_11{
        width: 215px;
        line-height: 18px !important;
        margin-top: 20px;
    }
    .box_11 span{
        font-weight: normal;
    }

    .box_12{
        width: 285px;
        line-height: 15px;
    }
    /*----------Fin Box----------*/



    /*----------Contenedores----------*/
    .contenedor_mapa{
        width: calc(100% - 40px);
        margin: auto;
        height: 150px;
    }

    .contenedor_3{
        padding: 0px 8%;
    }

    .contenedor_6{
        margin: 0px;
        margin-top: 0px;
        margin-bottom: 40px;
        padding: 0px 30px;
    }

    .contenedor_hospedarte{
        padding: 0px 0;
    }
    /*----------Fin Contenedores----------*/



    /*----------Flex----------*/
    .flex_padre_cerca_1{
        justify-content: space-evenly;
    }
    /*----------Fin Flex----------*/



    /*----------Fondos----------*/
    .fondo_1{
        padding: 10px 0%;
    }
    /*----------Fin Fondos----------*/
    


    /*----------Imagenes----------*/
    .imagen_cerca_1{
        margin: 10px;
        width: 80px;
        height: 50px;
    }

    .imagen_cerca_2{
        width: 154px;
        height: 154px;
        padding: 10px;
    }

    .imagen_cerca_3{
        width: 50%;
    }
    .imagen_cerca_3 img{
        width: 100%;
    }
    /*----------Fin Imagenes----------*/



    .video_1{
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
    }



    /*----------Titulos y textos----------*/
    .titulo_2{
        font-size: 12px;
    }
    /*----------Fin Titulos y textos----------*/
    

    
    .entorno{
        width: 300px;
    }
    .entorno > .titulo_9{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .contenedor_mapa{
        width: 84%;
        margin: auto;
    }

    .contenedor_3{
        padding: 0px 10%;
    }

    .contenedor_6{
        padding: 0px 5%;
    }

    .contenedor_hospedarte{
        padding: 0px 10%;
    }

    .video_1{
        width: 80%;
        margin: auto;
        margin-bottom: 30px;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .contenedor_6{
        padding: 0px 8%;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/

}