.subtitulo_igualdad{
    font-size: 18px;
}
.subtitulo_igualdad b{
    color: #A80B38;
}

@media only screen and (min-width:200px) and (max-width:550px){
    .subtitulo_igualdad{
        font-size: 13px;
    }
}
@media only screen and (min-width:551px) and (max-width:980px){}
@media only screen and (min-width:981px) and (max-width:1664px){}
@media only screen and (min-width:1665px){}