.boton_comunicado{
    margin: 30px 0 50px 0;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    width: 250px;
    background-color: #A80938;
    border: solid 1px #A80938;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.boton_comunicado p{
    margin: 12px;
    text-align: center;
    color: white;
    transition: all 0.5s ease-in-out;
}
.boton_comunicado:hover{
    box-shadow: 0px 0px 15px #A80938;
    transition: all 0.5s ease-in-out;
}

.boton_platicar{
    margin: 25px 0 50px 0;
    height: 50px;
    width: 220px;
    background-color: white;
    border: solid 1px #970D19;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.boton_platicar:hover{
    box-shadow: 0px 0px 15px #970D19;
    transition: all 0.5s ease-in-out;
}
.boton_platicar p{
    margin: 15px;
    text-align: center;
    color: black;
}

.box_mueve_1{
    width: 450px;
    height: 157px;
    margin: 20px;
}

.box_mueve_2{
    width: 450px;
    padding: 10px;
    margin: 20px;
    height: 157px;
}

.box_rodaje_titulo{
    width: 360px;
    padding: 0 2%;
}

.box_rodaje_1{
    width: 360px;
    height: 180px;
    padding: 0 2%;
}

.box_rodaje_2{
    width: 360px;
    height: 180px;
    padding: 0 2%;
}

.box_rodaje_3{
    width: 360px;
    height: 180px;
    padding: 0 2% ;
}

.collapsible-header_mueve .collapsible-header_propio {
    background-color: #F8F8F8;
}

.contenedor_global{
    width: 310px;
}

.contenedor_todo{
    width: 100%;
}

.contenedor_texto_caav_titulo{
    width: 286px;
}

.contenedor_texto_caav_mueve_3{
    width: 286px;
}

.contenedor_texto_caav_mueve_4{
    width: 286px;
}

.contenedor_texto_caav_mueve_5{
    width: 286px;
}

.contenedor_mueve_galeria{
    margin-bottom: 50px;
}

.contenedor_mueve_preguntas{
    padding: 0 0% 0 4%;
}

.flex_padre_mueve{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.flex_padre_mueve_2{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.fondo_mueve_preguntas{
    background-color: #F8F8F8;
    padding: 40px 0% 40px;
}

.fondo_mueve{
    background-color: #E3E3E3;
    padding: 20px 0% 40px;
}

.imagen_contactos{
    width: 100%;
}

.imagenes_mueve{
    min-height: 190px;
    height: 190px;
    position: relative;
    right: 50%;
}

.d2_titulo_2{
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    padding: 0px 5%;
}

.titulo_caav_mueve_1{
    font-size: 25px;
    font-weight: bold;
}

.titulo_caav_mueve_1_preguntas{
    font-size: 25px;
    font-weight: bold;
}

.texto_caav_mueve_1{
    font-size: 13px;
    line-height: 20px;
    padding: 0px 25px;
}

.d2_titulo_plan_rodaje{
    font-size: 17px;
    font-weight: bold;
    line-height: 25px;
    padding: 0px 5%;
    text-align: center;
}

.fab{
    color: #60C768;
}


.flex_mueve{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 642px;
}
.hijo_1_mueve{
    width: calc((100% / 4) - 14px);
    aspect-ratio: 1 / 1;
    overflow: hidden;
    margin: 7px;
}

.que_pasa_contenedor p{
    margin: 0px;
}

/* transicion collapsible */
.flecha_rodata{
    animation: rotar 0.2s linear forwards;
}

@keyframes rotar {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

.flecha_no_rodata{
    animation: rotar_2 0.2s linear forwards;
}

@keyframes rotar_2 {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* transicion collapsible */

@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/
    .que_pasa_contenedor p{
        padding: 0px 40px;
    }

    .contenedor_chico_mueve{
        width: 55%;
    }

    .boton_platicar{
        margin: 10% 0% 10% 0%;
    }
    
    .box_mueve_1{
        width: 310px;
        padding: 10px 10px;
        height: 120px;
        margin: 20px;        
    }
    
    .box_mueve_2{
        width: 310px;
        padding: 0 10px;
        margin: 20px;
        margin-top: -3px;
        height: 92px;
    }

    .box_rodaje_titulo{
        width: 375px;
        padding: 0 75px;
        margin: 0px;
    }

    .box_rodaje_1{
        width: 375px;
        height: 180px;
        padding: 0 75px;
        margin: 0px;
    }
    
    .box_rodaje_2{
        width: 350px;
        height: 80px;
        padding: 0px 55px;
        margin: -25px;
    }
    
    .box_rodaje_3{
        width: 350px;
        height: 40px;
        padding: 0px 0px;
        margin: 20px;
    }

    .contenedor_global{
        width: 100%;
    }
    
    .contenedor_caav_mueve{
        padding: 0px;
    }

    .contenedor_texto_caav_mueve_1{
        padding: 0 28px;
    }
   
    .contenedor_texto_caav_mueve_2{
        padding: 0 28px;
    }

    .contenedor_texto_caav_titulo{
        width: 240px;
    }
    
    .contenedor_texto_caav_mueve_3{
        width: 240px;
    }
   
    .contenedor_texto_caav_mueve_4{
        width: 240px;
    }
   
    .contenedor_texto_caav_mueve_5{
        width: 240px;
    }

    .contenedor_mueve_movil{
        padding: 0 10%;
    }

    .contenedor_mueve_galeria{
        margin-bottom: 0;
    }

    .contenedor_mueve_preguntas{
        padding: 0;
    }

    .flex_padre_mueve{
        margin-top: -20px;
    }

    .fondo_mueve{
        padding: 0 70px;
    }

    .d2_titulo_2{
        font-size: 12px;
        line-height: 15px;
        padding: 0;
        margin-top: 5px;
    }

    .titulo_caav_mueve_1{
        font-size: 16px;
    }

    .titulo_caav_mueve_1_preguntas{
        font-size: 14px;
        font-weight: bold;
    }

    .texto_caav_mueve_1{
        font-size: 12px;
        line-height: 15px;
        padding: 0px;
        margin-bottom: 5px;
        padding-right: 15px;
        width: 304px;
    }

    .d2_titulo_plan_rodaje{
        font-size: 12px;
        line-height: 0px;
        padding: 0;
        margin-top: 5px;
        text-align: center;
    }

}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    
    .boton_platicar{
        margin: 10% 0% 10% 0%;
    }

    .box_mueve_1{
        width: 60%;
        padding: 10px 10px;
    }

    .box_mueve_2{
        margin-top: 20px;
        width: 60%;
        padding: 0 10px;
    }

    .contenedor_caav_mueve{
        padding: 0;
    }

    .fondo_mueve{
        padding: 0 5% 0% 5%;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    
    .contenedor_caav_mueve{
        min-width: 850px;
        max-width: 900x;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/
    
    .contenedor_caav_mueve{
        width: 1200px;
    }
}