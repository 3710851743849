.boton_licenciatura_3{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    height: 43px;
    background-color: #072474;
    border: solid 1px #072474;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    padding: 0px 10px;
    font-family: 'Montserrat';
}
.boton_licenciatura_3:focus{
    background-color: #072474;
}
.boton_licenciatura_3:hover{
    box-shadow: 0px 0px 15px #072474;
    transition: all 0.5s ease-in-out;
}

/* .contenedor_d3{
    padding: 15px 10%;
}

.contenedor_2_d3{
    padding: 30px 20%;
}

.contenedor_lic_plan{
    padding: 15px 10%;
}

.flex_padre_cine{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.flex_1{
    width: 320px;
}

.flex_2_d3{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
}

.hijo_1_d3 {
    padding: 0px 0px 30px 0px;
}

.logo_yo_creo{
    width: 150px;
}

.imagen_lic{
    width: 50px;
}

.titulo_licenciatura{
    margin-top: 10px;
} */

/* .texto_3{
    font-family: "Montserrat";
    font-size: 14px;
} */

.texto_licenciatura_campo_trabajo_14{
    width: 608px;
    font-size: 14px;
}

.texto_lic_2{
    font-size: 42px;
    font-Weight: bold;
    margin-top: 25px;
    margin-bottom: 33px;
}

.numero_d3_mu{
    font-family: 'Jost-ExtraBold';
    font-size: 42px;
    color: #3EDBF0;
    padding: 10px 0px !important;
}

.texto_licenciatura_campo_trabajo_4{
    width: 535px;
    font-size: 14px;
}

.texto_licenciatura_carrera_5{
    font-size: 14px;
    width: 579px;
    line-height: 23px;
    padding: 10px 39px;
}

.texto_licenciatura_campo_trabajo_8{
    width: 844spx;
    font-size: 14px;
}

.texto_licenciatura_campo_trabajo_9{
    width: 340px;
    font-size: 14px;
}

.texto_licenciatura_campo_trabajo_10{
    width: 290px;
    font-size: 14px;
}

.numero_d3_3{
    font-size: 50px;
    color: #FB912B;
    padding: 10px 0px !important;
}

/* .texto_licenciatura_carrera_4{
    font-size: 14px;
    width: 579px;
    line-height: 23px;
    padding: 10px 33px;
} */

.texto_licenciatura_campo_trabajo_11{
    width: 753px;
    font-size: 14px;
}

.texto_licenciatura_campo_trabajo_12{
    width: 410px;
    font-size: 14px;
}
.texto_licenciatura_campo_trabajo_12 > ul > li::before{
    content: url("../../../Images/Icon/56.png");
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -10px;
    margin-top: -3px;
    padding-bottom: 5px;
    position: absolute;
}

.texto_licenciatura_campo_trabajo_13{
    width: 300px;
    font-size: 14px;
}
.texto_licenciatura_campo_trabajo_13 > ul > li::before{
    content: url("../../../Images/Icon/56.png");
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -10px;
    margin-top: -3px;
    padding-bottom: 5px;
    position: absolute;
}


.puntos_2_nv > ul > li::before{
    content: url("../../../Images/Icon/56.png");
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -10px;
    margin-top: -3px;
    padding-bottom: 5px;
    position: absolute;
}



@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/

    .boton_licenciatura_3{
        display: flex;
        color: #ffffff;
        margin-top: 30px;
        font-size: 13px;
        font-weight: bold;
        height: 43px;
        background-color: #072474;
        border: solid 1px #072474;
        border-radius: 10px;
        transition: all 0.5s ease-in-out;
    }
    .boton_licenciatura_3{
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .contenedor_habilidades_m{
        text-align: left !important;
    }

    /* .contenedor_2_d3{
        padding: 20px 15%;
    }

    .contenedor_lic_plan{
        padding: 15px 15%;
    }

    .flex_movil_2{
        padding: 0 0px 0 15px;
    }

    .flex_padre_cine{
        justify-content: center;
    }

    .flex_1{
        width: 100%;
    }

    .flex_movil_4{
        padding: 0 55px 0 15px;
    }

    .flex_movil_7 > div > p{
        text-align: center;
    } */

    .flex_movil_5{
        padding: 0 50px;
    }

    .flex_movil_6{
        padding: 0 75px 0 50px;
    }
    .flex_movil_6 > div{
        text-align: center;
    }

    /* .hijo_1_d3{
        width: 500px;
    }

    .logo_yo_creo{
        width: 65px;
        margin: 0;
    }

    .imagen_lic{
        width: 28px;
        margin-top: 10px;
    } */

    /* .texto_licenciatura_perfil_ingreso_2{
        font-size: 10px;
        width: 579px;
        line-height: 15px;
        padding: 0;
        margin: 0px 0 0 0;
    } */

    .titulo_lic_perfil{
        font-size: 10px;
    }

    .texto_licenciatura_campo_trabajo_14{
        width: 290px;
        font-size: 10px;
    }

    .texto_lic_2{
        font-size: 17px;
        font-Weight: bold;
        margin-top: 25px;
        margin-bottom: 33px;
    }

    .numero_d3_mu{
        font-size: 20px;
    }

    .texto_licenciatura_campo_trabajo_4{
        width: 302px;
        font-size: 10px;
    }

    .texto_licenciatura_carrera_5{
        font-size: 10px;
        width: 289px;
        line-height: 15px;
        padding: 0px;
    }

    .texto_licenciatura_campo_trabajo_8{
        width: 258px;
        font-size: 10px;
    }

    .texto_licenciatura_campo_trabajo_9{
        width: 260px;
        font-size: 10px;
        margin-bottom: -22px;
    }

    .texto_licenciatura_campo_trabajo_10{
        width: 260px;
        font-size: 10px;
        margin-top: 0;
    }

    /* .texto_licenciatura_carrera_4{
        font-size: 10px;
        width: 251px;
        line-height: 15px;
        padding: 0px;
    } */

    .texto_licenciatura_campo_trabajo_11{
        width: 236px;
        font-size: 13px;
    }

    .texto_licenciatura_campo_trabajo_12{
        width: 240px;
        font-size: 13px;
    }
    .texto_licenciatura_campo_trabajo_12 > ul{
        margin: 0px;
    }

    .texto_licenciatura_campo_trabajo_13{
        width: 240px;
        font-size: 10px;
    }
    .texto_licenciatura_campo_trabajo_13 > ul{
        margin: 0px;
    }

    .altura_video_licenciatura{
        height: 220px;
    }

    .puntos_2_nv > p{
        margin: 0px;
    }

    .movil_warp{
        flex-wrap: wrap;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .boton_licenciatura_3{
        display: flex;
        color: #ffffff;
        margin-top: 30px;
        font-size: 13px;
        font-weight: bold;
        height: 43px;
        background-color: #072474;
        border: solid 1px #072474;
        border-radius: 10px;
        transition: all 0.5s ease-in-out;
    }

    .texto_licenciatura_campo_trabajo_12{
        width: 240px;
        font-size: 13px;
    }
    .texto_licenciatura_campo_trabajo_12 > ul{
        margin: 0px;
    }
    
    .width_tablet{
        width: 435px;
    }

    .hijo_1_d3{
        width: 33.33333%;
    }

    .altura_video_licenciatura{
        height: 400px;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .hijo_1_d3{
        width: 33.33333%;
    }

    .altura_video_licenciatura{
        height: 500px;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/
    .hijo_1_d3{
        width: 33.33333%;
    }

    .altura_video_licenciatura{
        height: 600px;
    }
}