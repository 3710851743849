#formulario-reinscripcion .select-wrapper input.select-dropdown{
    width: calc(100% - 42px) !important;
}
#formulario-reinscripcion .select-wrapper svg{
    right: 10px !important;
}
#formulario-inscripcion .select-wrapper input.select-dropdown{
    width: calc(100% - 42px) !important;
}
#formulario-inscripcion .select-wrapper svg{
    right: 10px !important;
}

.select-wrapper .caret{
    z-index: 1 !important;
}

.select-formulario > div > input {
    font-family: 'Montserrat' !important;
    padding: 0px 19px !important;
    width: calc(100% - 25px) !important;
    font-size: 14px !important;
    color: #5F5F5F !important;
    border: solid 1px #EFF2F8 !important;
    border-radius: 16px !important;
    background: #EFF2F8 !important;
}

.formulario{
    font-family: 'Montserrat' !important;
    border: solid 1px #EFF2F8 !important;
    border-radius: 16px !important;
    background: #EFF2F8 !important;
    font-style: normal !important;
    padding: 0 20px !important;
    width: calc(100% - 40px) !important;
}
.formulario::placeholder {
    padding: 0;
    font-size: 14px;
    font-style: normal;
    color: #5F5F5F;
}

.text_examinar{
    width: 100%;
    height: 50px;
    margin: 50px 25px;
}

.input_documentos{
    font-family: 'Montserrat' !important;
    border: solid 1px #EFF2F8 !important;
    border-radius: 16px !important;
    background: #EFF2F8 !important;
}
.input_documentos::placeholder{
    padding-left: 20px;
    font-size: 14px;
    font-style: medium italic;
    color: #A5A5A5;
}

.input-field p {
    margin: 0 0 4px 15px;
    color: #6E6E73;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.boton_examinar {
    height: 40px !important;
    width: 188px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 3px solid #6E6E73;
    background: transparent;
}
.boton_examinar input{
    height: 40px !important;
    width: 188px !important;
    border-radius: 100px;
}
.boton_examinar p{
    margin: 0 !important;
}

.check-box-label{
    color: #6E6E73 !important;
}

[type="checkbox"].filled-in:not(:checked)+span:not(.lever):after{
    border-radius: 4px !important;
    border: 2px solid #6E6E73 !important;
}

[type="checkbox"].filled-in:checked+span:not(.lever):after{
    border-radius: 4px !important;
    border: 2px solid #DE2657 !important;
    background-color: #DE2657 !important;
}

.boton_2_rein{
    display: flex;
    width: 303px;
    height: 46px;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    border-radius: 980px;
    background: #DE2657;
    border: #DE2657;
    color: #fff;
}

.boton_2_rein-des{
    display: flex;
    width: 303px;
    height: 46px;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    border-radius: 980px;
    background: #b8b8b8;
    border: #b8b8b8;
    color: #fff;
}