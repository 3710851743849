.flex_header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.caav_header{
    width: 100%;
    font-size: 55px;
    text-align: center; 
    font-family: noodle; 
    color: white;
    margin: 0px;
    margin-bottom: -20px;
    margin-top: 20px;
}

.red_caav a{
    color: #970D19;
}
.red_caav > p{
    margin: 0px;
}


.collapsible-header > p{
    margin: 0px;
}

.header_2 > li > a:focus-visible{
    outline: none !important;
}

.hover_opciones > a{
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
}
.hover_opciones:hover > a{
    font-size: 20px !important;
    transition: all 0.3s ease-in-out;
}

.hover_opciones > button{
    font-size: 1rem;
    border: none;
    background-color: transparent;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
}
.hover_opciones:hover > button{
    font-size: 20px !important;
    transition: all 0.3s ease-in-out;
}

.menu_header{
    position: fixed;
    z-index: 1002 !important;
}

.menu_lista{
    height: 50px;
    margin-top: 5px;
}
.menu_lista li{
    height: 50px;
}

.span-header{
    position: relative;
    height: 60px !important;
}
.span-header > a::after{
    content: "Ingreso anual";
    font-size: 12px;
    margin: 5px 15px;
    color: #154C95;
    position: absolute;
    bottom: 0;
    left: 0px;
}

.span-header-2{
    position: relative;
}
.span-header-2 > a::after{
    content: "Ingreso anual";
    font-size: 12px;
    line-height: 0px;
    margin: 5px 15px;
    color: #154C95;
    position: absolute;
    bottom: 0;
    left: 0px;
}


@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/
    .menu_header[data-className="false"]{
        background: #F8F8F8;
        height: 55px;
    }
    .menu_header[data-className="true"]{
        background: #F8F8F8;
        height: 98px;
    }
    .invisible_movil{
        display: none;
    }
    [data-className="false"] ~ .menu_header_alto{
        height: 55px;
    }
    [data-className="true"] ~ .menu_header_alto{
        height: 98px;
    }

    .caav_header{
        font-size: 30px;
    }
    .header_3{
        display: flex;
        align-items: center;
        height: 40px !important;
    }

    .header_3 > button{
        font-size: 1rem;
        border: none;
        background-color: transparent;
        color: #000000de;
        transition: all 0.3s ease-in-out;
        padding: 0 32px;
    }
}

@media only screen and (min-width:551px) and (max-width:980px){ /*----------Tablet----------*/
    .menu_header[data-className="false"]{
        background: #00496c;
        height: 55px;
        top: 0px;
    }
    .menu_header[data-className="true"]{
        background: #00496c;
        height: 118px;
        top: 0px;
    }
    [data-className="false"] ~ .menu_header_alto{
        height: 55px;
    }
    [data-className="true"] ~ .menu_header_alto{
        height: 125px;
    }

    .caav_header{
        font-size: 35px;
        margin: 0px;
    }

    .invisible_escritorio{
        display: none;
    }
}

@media only screen and (min-width:981px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .contendor_header{
        width: 100%;
    }

    .menu_header[data-className="false"]{
        background: #00496c;
        height: 133px;
        top: 0px;
    }
    .menu_header[data-className="true"]{
        background: #00496c;
        height: 185px;
        top: 0px;
    }
    [data-className="false"] ~ .menu_header_alto{
        height: 133px;
    }
    [data-className="true"] ~ .menu_header_alto{
        height: 185px;
    }

    .caav_header{
        font-size: 40px;
    }

    .invisible_escritorio{
        display: none;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/
    .contendor_header{
        width: 100%;
    }

    .menu_header[data-className="false"]{
        background: #00496c;
        height: 125px;
        z-index: 99;
        top: 0px;
    }
    .menu_header[data-className="true"]{
        background: #00496c;
        height: 185px;
        z-index: 99;
        top: 0px;
    }

    [data-className="false"] ~ .menu_header_alto{
        height: 125px;
    }
    [data-className="true"] ~ .menu_header_alto{
        height: 185px;
    }

    .invisible_escritorio{
        display: none;
    }
}