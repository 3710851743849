/* nuevo css */


.flex_padre_1_nosotros{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.caja_1_nosotros{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 260px;
}

@media only screen and (min-width:200px) and (max-width:550px){
    .caja_1_nosotros{
        margin-top: 20px;
    }

    .contenedor_1_nosotros{
        padding: 0px 5%;
    }

    .box_1_nosotros p{
        margin: 0px;
        text-align: center;
    }

    .flex_padre_1_nosotros{
        flex-direction: column;
        justify-content: center;
    }
}
@media only screen and (min-width:551px) and (max-width:980px){
    .contenedor_1_nosotros{
        padding: 0px 11%;
    }
}
@media only screen and (min-width:981px) and (max-width:1664px){
    .contenedor_1_nosotros{
        padding: 0px 15%;
    }
}
@media only screen and (min-width:1665px){
    .contenedor_1_nosotros{
        padding: 0px 31%;
    }
}
/* fin - nuevo css */