/*----------Collapsible----------*/
.keyboard-focused .collapsible-header:focus{
    background: transparent !important;
}

.titulacion-collapsible{
    border: none;
    box-shadow: none;
}
.titulacion-collapsible > li > div{
    background: transparent !important;
    padding: 0px !important;
    border: none;
}

.collapsible_animacion{
    box-shadow: none;
    border: none;
    color: #ffffff;
    background: #752884;
}
.collapsible_animacion > li > div{
    border: none;
    background: #752884;
}
/*----------Fin Collapsible----------*/



/*----------Contenedor----------*/
.contenedor_7{
    padding: 0px 16%;
}

/* .contenedor_8_animacion{
    padding: 0px 29px;
} */

.contenedor_animacion_1{
    width: 677px;
    text-align: center;
}
/*----------Fin Contenedor----------*/



/*----------Flex----------*/
.flex_padre_3{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.flex_padre_titulacion{
    display: flex;
    justify-content: center;
}

.flex_hijo_titulacion{
    display: flex;
    justify-content: center;
    width: 33.33333%;
}

.flex_hijo_titulacion_contenido{
    display: flex;
    align-items: center;
}

/* .flex_hijo_5{
    margin: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
} */
/* .flex_hijo_5_2{
    margin: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
} */
/* .flex_hijo_5 h2{
    margin-top: 15px;
} */

/* .flex_padre_grados_animacion{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
} */
/*---------Fin Flex---------*/



/*----------Texto y título----------*/
.titulacion_titulo{
    font-size: 55px;
    font-family: 'Montserrat-Bold';
}
.titulo_2{ 
    font-size: 20px;
    font-weight: bold;
}

.titulo_4_animacion{
    font-size: 15px;
    font-weight: bold;
}

/*----------Fin Texto y título----------*/



/* .borde_1_animacion{
    border-right: solid 1px #124667;
    border-left:solid 1px #124667;
    margin: 0;
} */

.padding_1{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.posicion_animacion{
    position: relative;
    left: -13px;
}
.grados_animacion{
    margin-top: 0px;
}
.grados_animacion span{
    font-size: 40px !important;
    position:relative;
    top:15px;
}



@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/
    .posicion_animacion{
        position: relative;
        left: 0px;
    }
    .grados_animacion{
        margin: 12px 0px;
    }
    .grados_animacion span{
        top:20px;
        left: -20px;
    }
    .sin_padding_movil{
        padding: 0px !important;
    }

    .contenedor_animacion_1{
        width: 300px;
        text-align: center;
    }

    .contenedor_7{
        padding: 0%;
    }

    /* .contenedor_8_animacion{
        padding: 0px 52px;
    } */
    /* .contenedor_8_animacion p{
        margin: 10px 0px;
    } */

    /* .contenedor_titulacion_grados{
        text-align: center;
    } */

    /* .flex_padre_grados_animacion{
        width: 100%;
        font-weight:bold ;
    } */
    .posicion_animacion {
        font-family:'Jost-ExtraBold';
    }
    /* .flex_padre_grados_animacion h2{
        font-family:'Jost-ExtraBold';
        margin-bottom: 0px;
    } */
    .flex_padre_3{
        padding: 10px;
    }
    /* .flex_hijo_5_2{
        margin: 0px 10px;
        text-align: left !important;
    } */

    .titulo_2{
        font-size: 12px;
    }

    /* .borde_1_animacion{
        border: none;
        border-top: solid 1px #124667;
        border-bottom:solid 1px #124667;
        padding: 0px 0px 15px !important;
    } */

    .flex_hijo_titulacion{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .flex_padre_titulacion{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .bordes_titulacion{
        border-top: 1px solid;
        border-bottom: 1px solid;
    }
}



@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .contenedor_7{
        padding: 0px;
    }

    /* .contenedor_8{
        padding-left: 12%;
    } */

    .bordes_titulacion{
        border-left: 1px solid;
        border-right: 1px solid;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .contenedor_7{
        padding: 0px 5%;
    }

    .bordes_titulacion{
        border-left: 1px solid;
        border-right: 1px solid;
    }
}

@media only screen and (min-width:851px){
    .bordes_titulacion{
        border-left: 1px solid;
        border-right: 1px solid;
    }    
}