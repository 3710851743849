
.boton_licenciatura_4{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    height: 43px;
    background-color: #607DBE;
    border: solid 1px #607DBE;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    padding: 0px 10px;
    font-family: 'Montserrat';
}
.boton_licenciatura_4:focus{
    background-color: #607DBE;
}
.boton_licenciatura_4:hover{
    box-shadow: 0px 0px 15px #607DBE;
    transition: all 0.5s ease-in-out;
}

/* .contenedor_d3{
    padding: 15px 10%;
}

.contenedor_2_d3{
    padding: 30px 20%;
}

.contenedor_lic_plan{
    padding: 15px 10%;
}

.flex_padre_cine{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.flex_1{
    width: 320px;
}

.flex_2_d3{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
} */

/* .hijo_1_d3 {
    padding: 0px 0px 30px 0px;
}

.logo_yo_creo{
    width: 150px;
}

.imagen_lic{
    width: 50px;
}

.titulo_licenciatura{
    margin-top: 10px;
} */

.texto_licenciatura_perfil_ingreso_ma{
    font-size: 14px;
    width: 481px;
    line-height: 15px;
    padding: 10px 0px;
    margin: 0px 20px;
    flex-wrap: wrap;
}

/* .texto_3{
    font-family: "Montserrat";
    font-size: 14px;
} */

.texto_licenciatura_campo_trabajo_14{
    width: 608px;
    font-size: 14px;
}

.texto_lic_2{
    font-size: 42px;
    font-Weight: bold;
    margin-top: 25px;
    margin-bottom: 33px;
}

.numero_d3{
    font-size: 50px;
    color: #00A288;
    padding: 10px 0px !important;
}

.texto_licenciatura_campo_trabajo_4{
    width: 535px;
    font-size: 14px;
}

.texto_licenciatura_carrera_5_m{
    font-size: 13px;
    width: 584px;
    line-height: 23px;
    padding: 10px 39px;
}

.texto_licenciatura_campo_trabajo_8_ma{
    width: 844px;
    font-size: 14px;
}

.texto_licenciatura_campo_trabajo_9{
    width: 340px;
    font-size: 14px;
}
.texto_licenciatura_campo_trabajo_9 > ul > li::before{
    content: url("../../../Images/Icon/56.png");
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -10px;
    margin-top: -3px;
    padding-bottom: 5px;
    position: absolute;
}

.texto_licenciatura_campo_trabajo_10{
    width: 290px;
    font-size: 14px;
}
.texto_licenciatura_campo_trabajo_10 > ul > li::before{
    content: url("../../../Images/Icon/56.png");
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -10px;
    margin-top: -3px;
    padding-bottom: 5px;
    position: absolute;
}

.numero_d3_m{
    font-family: 'Jost-ExtraBold';
    font-size: 42px;
    color: #154C95;
    padding: 10px 0px !important;
}

@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/

    .numero_d3_m{
        font-size: 20px;
    }

    .boton_licenciatura_4{
        display: flex;
        color: #ffffff;
        margin-top: 30px;
        font-size: 13px;
        font-weight: bold;
        height: 43px;
        background-color: #607DBE;
        border: solid 1px #607DBE;
        border-radius: 10px;
        transition: all 0.5s ease-in-out;
    }
    
    .boton_licenciatura_4{
        margin-top: 20px;
        margin-bottom: 25px;
    }

    /* .contenedor_2_d3{
        padding: 20px 15%;
    }

    .contenedor_lic_plan{
        padding: 15px 15%;
    }

    .flex_movil_2{
        padding: 0 0px 0 15px;
    }

    .flex_padre_cine{
        justify-content: center;
    }

    .flex_1{
        width: 100%;
    } */

    .flex_movil_4{
        padding: 0 55px 0 15px;
    }

    .flex_movil_7 > div > p{
        text-align: center;
    }

    /* .hijo_1_d3{
        width: 500px;
    }

    .logo_yo_creo{
        width: 65px;
        margin: 0;
    }

    .imagen_lic{
        width: 28px;
        margin-top: 10px;
    } */

    .texto_licenciatura_perfil_ingreso_ma{
        font-size: 10px;
        width: 268px;
        line-height: 15px;
        padding: 0 0 10px;
    }

    .titulo_lic_perfil{
        font-size: 10px;
    }

    .texto_licenciatura_campo_trabajo_14{
        width: 290px;
        font-size: 10px;
    }

    .texto_lic_2{
        font-size: 17px;
        font-Weight: bold;
        margin-top: 25px;
        margin-bottom: 33px;
    }

    .numero_d3{
        font-size: 17px;
    }

    .texto_licenciatura_campo_trabajo_4{
        width: 302px;
        font-size: 10px;
    }

    .texto_licenciatura_carrera_5_m{
        font-size: 13px;
        width: 245px;
        padding: 0px;
    }

    .texto_licenciatura_campo_trabajo_8_ma{
        width: 258px;
        font-size: 10px;
    }

    .texto_licenciatura_campo_trabajo_9{
        width: 260px;
        font-size: 10px;
        margin-bottom: -22px;
    }

    .texto_licenciatura_campo_trabajo_10{
        width: 260px;
        font-size: 10px;
        margin-top: 0;
    }

    .altura_video_licenciatura{
        height: 220px;
    }

    .padding_movil_1 > ul{
        padding: 0px 15px;
    }

    .line_heigth_2 > ul > li{
        margin: 3px !important;
    }

    .movil_warp{
        flex-wrap: wrap;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .boton_licenciatura_4{
        display: flex;
        color: #ffffff;
        margin-top: 30px;
        font-size: 13px;
        font-weight: bold;
        height: 43px;
        background-color: #607DBE;
        border: solid 1px #607DBE;
        border-radius: 10px;
        transition: all 0.5s ease-in-out;
    }

    .texto_licenciatura_campo_trabajo_8_ma{
        width: 540px;
    }

    .hijo_1_d3{
        width: 33.33333%;
    }

    .altura_video_licenciatura{
        height: 400px;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .hijo_1_d3{
        width: 33.33333%;
    }

    .altura_video_licenciatura{
        height: 500px;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/
    .hijo_1_d3{
        width: 33.33333%;
    }

    .altura_video_licenciatura{
        height: 600px;
    }
}