.buscador_profesores{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
  
.buscador_profesores > button {
    margin: 5px;
    border: none;
    background-color: white;
    cursor: pointer;
}

/*----------Box----------*/
.box_profesor{
    margin: 0% 0% 20% 15%;
}
/*----------Fin Box----------*/

/*----------Contenedores----------*/
.contenedor_2{
    padding: 5px 10%;
}

.contenedor-hover-1 img{
    border-radius: 15px;
    width: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
}

.contenedor-hover-2 img{
    width: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
}
/*----------Fin Contenedores----------*/

/*----------Formularios----------*/
.text_profesores{
    width: 585px;
    height: 45px;
}

.titulo_profesores{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}

.formulario{
    border: solid 1px #707070 !important;
    border-radius: 10px !important;
    
}
.formulario::placeholder {
    padding-left: 20px;
    font-size: 11px;
    font-style: italic;
    color: #5F5F5F;
}
/*----------Fin Formularios----------*/



/*----------Titulos y textos----------*/

.d3_titulo_1{
    font-size: 30px;
    font-family: 'Montserrat-Bold';
}

.texto_nombre_prof{
    font-size: 14px;
    text-align: center;
}

.texto_nombre_prof_2{
    font-weight: bold;
    text-align: center;
}

.texto_materias{
    font-style: italic;
    text-align: center;
    margin-top: -10px;
}
.texto_materias p{
    margin: 0px;
}
/*----------Fin Titulos y textos----------*/


.collapsible-header_propio-profesor{
    border: none;
    height: auto;
}

li.active .collapsible-header_propio-profesor .material-icons.iconDown{
    display: none;
}

li.active .collapsible-header_propio-profesor .material-icons.iconUp{
    display: inline-block !important;
}



@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/

    /* inicio - flexbos */
    .hijo_1{
        width: 50%;
        padding: 10px;
    }

    .hijo_2{
        width: 50%;
        padding: 10px;
    }
    /* fin - flexbos */
    
    .buscador_profesores > button {
        margin: -3px;
        font-size: 11px;
    }

    .contenedor_2{
        padding: 5px 0px 0%;
    }

    .text_profesores{
        width: 354px;
        height: 33px;
        margin-bottom: 3%;
    }

    .texto_nombre_prof{
        font-size: 14px;
        font-weight: bold;
        text-align: right;
    }

    .texto_materias{
        font-style: normal;
        text-align: right;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    /* inicio - flexbos */
    .hijo_1{
        width: 50%;
        padding: 10px;
    }

    .hijo_2{
        width: 50%;
        padding: 10px;
    }
    /* fin - flexbos */

    .buscador_profesores > button {
        margin: -2px;
    }

    .box_profesor{
        margin: 0%;
    }

    .text_profesores{
        width: 450px;
        height: 33px;
    }

    .texto_nombre_prof{
        font-size: 11px;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    /* inicio - flexbos */
    .hijo_1{
        width: 33.33333%;
        padding: 10px;
    }

    .hijo_2{
        width: 25%;
        padding: 10px;
    }
    /* fin - flexbos */

    .buscador_profesores > button {
        margin: 5px;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/
    /* inicio - flexbos */
    .hijo_1{
        width: 33.33333%;
        padding: 10px;
    }

    .hijo_2{
        width: 25%;
        padding: 10px;
    }
    /* fin - flexbos */
}


/* nuevo css */
.contenedor-profesores-transicion{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    transition: all 0.5s ease-in-out;
}

.contenido-profesro{
    position: absolute;
    width: 200px;
    color: #ffffff;
    filter: opacity(0);
    transition: all 0.5s ease-in-out;
}

.contenedor-profesores-transicion:hover .contenido-profesro{
    filter: opacity(1);
    z-index: 8999;
}

/*----------Imagenes----------*/
.imagen_contactos{
    width: 100%;
}

.imagen_profesores{
    width: 100%;
}

.imagen-hover-1{
    cursor: default;
    transition: all 0.5s ease-in-out;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
}
.contenedor-profesores-transicion:hover .imagen-hover-1 img{
    transform: scale(1.1) ;
    filter: brightness(0.3);
    transition: all 0.3s ease-in-out;
    border-radius: 15px;
}
/*----------Fin Imagenes----------*/