@media only screen and (min-width:200px) and (max-width:550px){
    .flex-end{
        justify-content: end !important;
    }

    .flex-start{
        align-items: start !important;
    }

    .sin_separar_movil{
        margin-top: 0 !important;
    }
}
@media only screen and (min-width:551px) and (max-width:980px){}
@media only screen and (min-width:981px) and (max-width:1664px){
    .margin_top_1{
        margin-top: 42px;
    }
    .margin_top_2{
        margin-top: 34px;
    }
    .margin_top_3{
        margin-top: 16px;
    }
    .margin_top_4{
        margin-top: 12px;
    }
    .center_desktop{
        text-align: center !important;
    }

    .s_padin_escritorio{
        padding: 0 !important;
    }
}
@media only screen and (min-width:1665px){
    .margin_top_1{
        margin-top: 42px;
    }
    .margin_top_2{
        margin-top: 34px;
    }
    .margin_top_3{
        margin-top: 16px;
    }
    .margin_top_4{
        margin-top: 12px;
    }
    .center_desktop{
        text-align: center !important;
    }

    .s_padin_escritorio{
        padding: 0 !important;
    }
}
